<template>
  <v-dialog v-model="dialog" persistent max-width="750">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark plain v-bind="attrs" v-on="on">
        Edit
        <v-icon small class="mr-2" title="Knowledge Check Settings"
          >mdi-edit</v-icon
        >
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"> Knowledge Check Settings </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-switch
            v-model="model.isEnabled"
            label="Enable Knowledge Check"
          ></v-switch>
          <v-textarea
            label="Instructions"
            auto-grow
            v-model="model.instructions"
          ></v-textarea>
          <v-text-field
            label="Number of Questions"
            required
            type="number"
            min="1"
            :rules="numberRules"
            v-model="model.numberOfQuestions"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn tile large color="primary" @click="onSave" :disabled="!valid">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    api: { type: Object, required: true },
    value: { type: Object, required: true }
  },
  data() {
    return {
      dialog: false,
      model: {},
      valid: true,
      numberRules: [
        (v) => !!v || 'Number of questions is required',
        (v) => v > 0 || `Number of questions must be more than ${v}`
      ]
    }
  },
  watch: {
    value(val) {
      this.model = _.clone(val)
    }
  },
  mounted() {
    this.model = _.clone(this.value)
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
      this.dialog = false
    },
    async onSave() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      console.log(this.model)
      await this.api.knowledgeCheck.put(this.model.id, this.model)

      this.$emit('save', this.model)
      this.dialog = false
    }
  }
}
</script>
