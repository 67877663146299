<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <v-row class="heading">
      <h4>Module access for {{ this.userName }}</h4>
    </v-row>
    <v-row>
      <v-col>
        <div style="overflow-x: auto; overflow-y: auto">
          <table id="accessTable">
            <thead>
              <tr>
                <th v-for="header in headers" align="left">{{ header }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in rows" class="materialIcon">
                <td>{{ row.moduleName }}</td>
                <td v-if="row.permission === 'read'">
                  <v-icon>mdi-close-thick</v-icon>
                </td>
                <td v-else></td>
                <td v-if="row.permission === 'write'">
                  <v-icon>mdi-close-thick</v-icon>
                </td>
                <td v-else></td>
                <td v-if="row.permission === 'publish'">
                  <v-icon>mdi-close-thick</v-icon>
                </td>
                <td v-else></td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import appToolbar from '@/components/layout/app-toolbar'
import { moduleAccess as moduleAccessApi } from '@/services/api'
import _ from 'lodash'

export default {
  components: {
    appToolbar
  },
  data() {
    return {
      rows: [],
      model: [],
      userName: null,
      headers: ['Modules', 'View', 'Edit', 'Publish']
    }
  },
  mounted() {
    this.loadReportData()
  },
  methods: {
    async loadReportData() {
      const response = await moduleAccessApi.get()
      //const data = response.data.rows
      this.model = _.find(response.data.rows, ['userId', this.userId])
      this.userName = this.model.name
      this.rows = this.model
      delete this.rows.name
      delete this.rows.userId
    }
  },
  computed: {
    userId() {
      return this.$route.params.userId
    },
    memberId() {
      return this.$route.params.memberId
    },
    crumbs() {
      let items
      if (this.$route.params.route === 'membersAccess') {
        items = [
          { text: 'Reports', to: { name: 'reports-index' } },
          { text: 'Member Access Report', to: { name: 'members-access' } }
        ]
        if (this.model) {
          items.push({ text: this.userName, disabled: true })
        }
      } else if (this.$route.params.route === 'member') {
        items = [{ text: 'Members', to: { name: 'member-index' } }]
        if (this.model) {
          items.push(
            {
              text: this.userName,
              to: { name: 'member-detail', params: { memberId: this.memberId } }
            },
            { text: 'Module Access', disabled: true }
          )
        }
      }

      return items
    }
  }
}
</script>

<style scoped>
#accessTable {
  table-layout: auto;
  overflow: auto;
  border-collapse: collapse;
  width: 60%;
  font-size: 13px;
  padding: 15px;
}
#accessTable td,
#accessTable th {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  white-space: nowrap;
}
#accessTable tr:nth-child(even) {
  background-color: #f2f2f2;
}
#accessTable tr:hover {
  background-color: #ddd;
}
#accessTable th {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #ddd;
}
.heading {
  padding: 25px 0px 0px 15px;
}
</style>
