<template>
  <v-card flat tile class="mt-0">
    <v-card-title class="pa-0 subtitle-1"
      >{{ step.name }}
      <v-btn icon @click="edit" class="ml-1"
        ><v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="px-1">
      <viewer v-model="step.content" :options="options" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import viewer from '@/components/tiptap/viewer'

export default {
  components: {
    viewer
  },
  props: {
    step: { type: Object, required: true }
  },
  computed: {
    ...mapGetters('context', ['procedure', 'module', 'component']),
    options() {
      return {
        entityId: this.step.id,
        entityType: 'procedurestep',
        moduleId: this.module.id,
        componentId: this.component.id,
        procedureId: this.procedure.id,
      }
    },
  },
  methods: {
    edit() {
      this.$emit('edit', this.step)
    },
    reorder() {
      this.$emit('reorder')
    }
  }
}
</script>
