import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import IipProcedure from './component.vue'

export const inputRegex = /^\s*(#procedure:(\S*)?)\s$/

export default Node.create({
  name: 'iip-procedure',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      caption: {
        default: 'Procedure'
      },
      procedureId: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iip-procedure'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['iip-procedure', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(IipProcedure)
  },

  addCommands() {
    return {
      insertProcedure:
        () =>
          ({ chain }) => {
            return chain()
              .insertContent('<iip-procedure procedureId=""></iip-procedure>')
              .run()
          }
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , caption] = match
          return { caption }
        }
      })
    ]
  }
})
