<template>
  <div class="text-center">
    <v-dialog v-model="show" persistent class="modal" max-width="450">
      <v-app-bar flat elevation="0">
        <v-toolbar-title>Order Steps</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card flat tile>
        <v-card-text>
          <v-list dense>
            <draggable
              v-model="model"
              animation="200"
              handlesdfg=".drag-handle"
              ghost-class="drag-item-ghost"
              drag-class="drag-dragging-class"
              @start="drag = true"
              @end="drag = false"
              @change="onChange"
            >
              <transition-group
                type="transition"
                :name="!drag ? 'flip-list' : null"
              >
                <v-list-item
                  v-for="step in model"
                  :key="step.id"
                  class="drag-handle"
                >
                  <v-list-item-icon class="mr-1">
                    {{ step.order }}.
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title v-text="step.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </transition-group>
            </draggable>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click="cancel"> Cancel </v-btn>
          <v-btn tile large color="primary" @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    steps: { type: Array, default: () => [] },
    show: { type: Boolean, default: false }
  },
  data() {
    return {
      drag: false,
      model: []
    }
  },
  watch: {
    steps(val) {
      this.copySteps(val)
    }
  },
  mounted() {
    this.copySteps(this.steps)
  },
  methods: {
    copySteps(steps) {
      this.model = steps
        .map((step) => {
          return {
            id: step.id,
            name: step.name,
            order: step.order
          }
        })
        .sort((a, b) => a.order - b.order)
    },
    onChange() {
      this.model.map((step, idx) => {
        step.order = idx + 1
      })
    },
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save', this.model)
    }
  }
}
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
