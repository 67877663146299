<template>
  <div>
    <p class="text-center">
      This feature has been turned off. You can manage organizations in the
      identity server
    </p>
    <!-- <page-list
      title="Organizations"
      :to="{ name: 'organization-create' }"
      @search="onSearch"
      @clear="onSearchClear"
      :headers="headers"
      :items="organizations"
      :paging="paging"
      @page:changed="onPageChange"
      @row:view="view"
    >
      <template v-slot:filters>
        <v-checkbox
          v-model="includeAll"
          class="checkbox"
          label="Include deleted organizations"
        ></v-checkbox>
      </template>
    </page-list> -->
  </div>
</template>

<script>
import { organizations as organizationApi } from '@/services/api'
// import pageList from '@/components/layout/page-list.vue'

export default {
  components: {
    // pageList
  },
  data() {
    return {
      organizations: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: '', value: 'actions', sortable: false }
      ],
      includeAll: false,
      loaded: false,
      paging: {},
      search: ''
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const response = await organizationApi.get(null, {
        params: {
          term: this.search,
          page: this.paging.currentPage,
          includeAll: this.includeAll
        }
      })
      this.paging = response.data.meta.paging
      this.organizations = response.data.results
      this.loaded = true
    },
    onPageChange(pagination) {
      if (this.loaded) {
        this.paging.currentPage = pagination.page
        this.load()
      }
    },
    onSearchClear() {
      this.onSearch(null)
    },
    onSearch(term) {
      this.search = term
      this.paging.currentPage = 1
      this.load()
    },
    view(item) {
      this.$router.push({
        name: 'organization-detail',
        params: {
          organizationId: item.id
        }
      })
    }
  }
}
</script>
