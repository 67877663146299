export const mediaTypes = ['image', 'video', 'audio', 'document']

export const acceptMediaTypes = (mediaType) => {
  switch (mediaType) {
    case 'image':
      return 'image/*'
    case 'audio':
      return 'audio/*'
    case 'video':
      return 'video/*'
    case 'document':
      return '.pdf'
  }

  return 'image/*,audio/*,video/*,.pdf'
}

export const mediaTypePropertyValidator = (prop) => mediaTypes.includes(prop)
