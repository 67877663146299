var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-toolbar',{attrs:{"crumbs":_vm.crumbs}},[_c('v-spacer')],1),_c('version-chips',{model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),(_vm.model.status == 'published')?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" This version is already published ")]):_vm._e(),_c('validation-result',{attrs:{"validation":_vm.validation}}),_c('v-card',{staticClass:"mt-2",attrs:{"flat":"","tile":""}},[_c('v-card-actions',[(_vm.model.id)?_c('v-btn',{attrs:{"icon":"","color":"default","aria-label":"refresh"},on:{"click":_vm.onRefresh}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e(),_c('v-spacer'),(_vm.model.id)?_c('v-btn',{attrs:{"plain":"","color":"default","to":{
          name: 'topic-detail',
          params: {
            moduleId: _vm.moduleId,
            componentId: _vm.componentId,
            topicId: _vm.model.id
          }
        }}},[_vm._v(" Cancel ")]):_vm._e(),_c('v-btn',{attrs:{"tile":"","large":"","color":"primary","disabled":!_vm.canPublish},on:{"~click":function($event){return _vm.onPublish.apply(null, arguments)}}},[_vm._v(" Publish ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }