<script>
import { media } from '@/services/api'
import coverImage from '@/components/media/cover-image'

export default {
  components: {
    coverImage
  },
  methods: {
    saveCoverImage: async function (model, entityType) {
      if (model.coverImage && !model.coverImage.mediaId) {
        const coverImage = {
          name: model.coverImage.name,
          description: model.coverImage.description,
          entityId: model.id,
          entityType: entityType
        }

        const imageResponse = await media.api.postFile(
          model.coverImage.file,
          coverImage
        )

        model.coverImageId = imageResponse.referenceId

        return imageResponse
      }
    }
  }
}
</script>
