<template>
  <v-dialog v-model="show" min-width="450px">
    <v-app-bar flat elevation="0">
      <v-toolbar-title>Select Procedure</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card flat tile>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        @click:row="selectProcedure"
        :disable-pagination="true"
        :hide-default-footer="true"
        disable-sort
      >
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { procedures as proceduresApiBuilder } from '@/services/api'

export default {
  props: {
    value: { type: Boolean, default: false },
    options: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      items: [],
      procedureApi: null,
      procedureId: null,
      show: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status', width: '10%' },
        { text: 'Version', value: 'version', width: '10%', align: 'right' }
        // { text: '', value: 'actions', sortable: false, width: '10%' }
      ]
    }
  },
  mounted() {
    this.procedureApi = proceduresApiBuilder.build(
      this.options.moduleId,
      this.options.componentId
    )
    this.loadProcedures()
  },
  watch: {
    show(val) {
      this.$emit('input', val)
    },
    async value(val) {
      this.show = val
      if (val) {
        this.loadProcedures()
      }
    }
  },
  methods: {
    async loadProcedures() {
      const response = await this.procedureApi.get()
      this.items = response.data.results
      //Todo: check paging
    },
    async selectProcedure(item) {
      this.show = false
      this.$emit('selected', item)
    },
    cancel() {
      this.show = false
    }
  }
}
</script>
