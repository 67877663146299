<template>
  <div>
    <app-toolbar :crumbs="localCrumbs" />
    <div v-if="model.relations.length > 0">
      <question-form v-model="model" @save="onSave" @cancel="onCancel" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import contentPageMix from '@/components/mixins/content-page'
import { questions as questionsApi } from '@/services/api'
import QuestionForm from '@/components/knowledgeCheck/questions/form.vue'

export default {
  mixins: [contentPageMix],
  components: {
    QuestionForm
  },
  data() {
    return {
      model: {
        relations: []
      }
    }
  },
  computed: {
    ...mapGetters('messages', ['errors']),
    localCrumbs() {
      const items = [
        {
          text: 'Question',
          to: { name: 'questions-index', params: {} }
        }
      ]

      return items
    },
    entityId() {
      return this.$route.params.entityId
    },
    entityType() {
      return this.$route.params.entityType
    },
    mediaSelectorOptions() {
      return {
        entityId: this.entityId,
        entityType: this.entityType
      }
    }
  },
  mounted() {
    console.log('mounted', this.entityId, this.entityType)
    this.model.relations.push({
      entityId: this.entityId,
      entityType: this.entityType
    })
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      const response = await questionsApi.post(model)

      this.$router.push({
        name: 'question-detail',
        params: { questionId: response.data.id }
      })
    }
  }
}
</script>
