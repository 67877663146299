<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <procedure-form :value="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import { procedures as proceduresApiBuilder } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import procedureForm from './form'

export default {
  mixins: [contentPageMixin],
  components: {
    procedureForm
  },
  data() {
    return {
      model: {},
      proceduresApi: null
    }
  },
  computed: {
    crumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Edit',
        disabled: true
      })
      return items
    }
  },
  async mounted() {
    this.proceduresApi = await proceduresApiBuilder.build(
      this.moduleId,
      this.componentId
    )
    if (this.procedure) {
      this.model = _.cloneDeep(this.procedure)
    }
  },
  watch: {
    procedure(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('context', ['clearComponent']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      await this.proceduresApi.put(
        model.id,
        _.pick(model, ['id', 'name', 'description'])
      )

      const routeParams = {
        moduleId: this.moduleId,
        componentId: this.componentId,
        procedureId: this.model.id
      }

      await this.clearComponent()

      this.$router.push({
        name: 'procedure-detail',
        params: routeParams
      })
    }
  }
}
</script>

<style></style>
