var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isValididated)?_c('div',{staticClass:"text-center"},[_c('h4',[_vm._v("Validating")]),_c('v-progress-circular',{attrs:{"indeterminate":"","color":"green"}})],1):_vm._e(),(_vm.isValididated)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.headerMessage))]),_c('v-card',{attrs:{"tile":""}},[_c('v-list',{attrs:{"dense":""}},[_c('v-card-title',[_vm._v("Rules")]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedRule),callback:function ($$v) {_vm.selectedRule=$$v},expression:"selectedRule"}},_vm._l((_vm.validation.rules),function(r,i){return _c('rule-result',{key:i,attrs:{"rule":r}})}),1)],1)],1),(_vm.hasChildren)?_c('v-card',{staticClass:"mt-2",attrs:{"tile":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.childLabel))]),_c('v-card-text',[_c('v-treeview',{attrs:{"activatable":"","dense":"","open-all":"","items":_vm.validation.children},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.getFailedRules(item))+" "),(!item.succeeded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onItemClicked({ item: item, leaf: leaf })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-link-variant ")])],1):_vm._e()]}},{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.succeeded)?_c('v-icon',{attrs:{"dense":"","medium":"","color":"green darken-2"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"dense":"","medium":"","color":"red darken-2"}},[_vm._v(" mdi-alert-box ")]),(item.status == 'draft')?_c('v-icon',{attrs:{"dense":"","medium":"","color":"yellow darken-2","title":"new draft item"}},[_vm._v(" mdi-alert-decagram ")]):_vm._e()]}}],null,false,1064791585)})],1)],1):_vm._e(),(_vm.hasProcedures)?_c('v-card',{staticClass:"mt-2",attrs:{"tile":""}},[_c('v-card-title',[_vm._v("Procedures")]),_c('v-card-text',[_c('v-treeview',{attrs:{"activatable":"","dense":"","open-all":"","items":_vm.validation.procedures},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.getFailedRules(item))+" "),(!item.succeeded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onItemClicked({ item: item, leaf: leaf })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-link-variant ")])],1):_vm._e()]}},{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.succeeded)?_c('v-icon',{attrs:{"dense":"","medium":"","color":"green darken-2"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"dense":"","medium":"","color":"red darken-2"}},[_vm._v(" mdi-alert-box ")]),(item.status == 'draft')?_c('v-icon',{attrs:{"dense":"","medium":"","color":"yellow darken-2","title":"new draft item"}},[_vm._v(" mdi-alert-decagram ")]):_vm._e()]}}],null,false,1064791585)})],1)],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }