<template>
  <v-combobox
    v-model="select"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    cache-item
    clearable
    hide-no-data
    hide-details
    label="Member"
    placeholder="Search for member by name or email"
    auto-grow
    autocomplete="off"
  ></v-combobox>
</template>

<script>
import _ from 'lodash'
import { members as memberApi } from '@/services/api'

export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null
    }
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    select(val) {
      this.$emit('change', val ? val.value : null)
    }
  },
  methods: {
    querySelections: _.debounce(async function (v) {
      if (!v || v.length < 3 || (this.select && v == this.select.text)) {
        this.items = []
        return
      }

      this.loading = true

      const response = await memberApi.get(null, { params: { term: v } })

      const items = response.data.results.map((x) => {
        return { text: `${x.email}(${x.name})`, value: x }
      })
      this.items = items
      //this.select = null
      this.loading = false
    }, 300)
  }
}
</script>

<style></style>
