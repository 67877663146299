import Oidc from 'oidc-client'
import { UserManager, WebStorageStateStore } from 'oidc-client'

Oidc.Log.logger = console
Oidc.Log.level = Oidc.Log.ERROR

import {
  IDENTITY_SERVER_URL,
  IDENTITY_CLIENTID,
  API_NAME,
  APP_URL
} from '@/config'

class AuthService {
  userManager = null

  constructor() {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: IDENTITY_SERVER_URL,
      client_id: IDENTITY_CLIENTID,
      redirect_uri: `${APP_URL}callback.html`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${APP_URL}silent-renew.html`,
      response_type: 'code',
      scope: `openid profile ${API_NAME}`,
      post_logout_redirect_uri: `${APP_URL}logout`,
      filterProtocolClaims: true,
      loadUserInfo: true,
      monitorSession: false
    }

    this.userManager = new UserManager(settings)
  }

  getAccessToken() {
    return this.userManager.getUser().then((data) => {
      if (data) {
        return data.access_token
      }
      return null
    })
  }

  async getUser() {
    return this.userManager.getUser().then((user) => {
      return user
    })
  }

  login() {
    return this.userManager.signinRedirect()
  }

  logout() {
    localStorage.removeItem('iip.user:organization')
    return this.userManager.signoutRedirect()
  }

  refresh(route) {
    return this.userManager.signinSilent(route)
  }

  async isInRole(role) {
    const user = await this.getUser()
    return this.isUserInRole(user, role)
  }

  isUserInRole(user, role) {
    return user && user.profile.role.indexOf(role) >= 0
  }

  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback()
  }

  getUserOrganization(profile) {
    if (!profile) {
      console.log('getUserOrganization. profile is null')
      return null
    }

    // Default to previously selected org if there is on in local storage
    const storedOrg = localStorage.getItem('iip.user:organization')

    if (!storedOrg || storedOrg == 'null') {
      return profile.organization
    }

    try {
      const parsedOrg = JSON.parse(storedOrg)

      if (profile.isSysAdmin) {
        return parsedOrg
      }

      const membership = profile.membership.find((o) => {
        return o.organizationId === parsedOrg.organizationId
      })
      if (membership) {
        return membership
      }
    } catch {
      //no-op - we couldn't read stored organization so keep selectedOrg as default value from profile
    }

    return profile.organization
  }

  saveUserOrganization(organization) {
    localStorage.setItem('iip.user:organization', JSON.stringify(organization))
  }
}

export default new AuthService()
