<template>
  <v-card class="red" max-width="100%" flat>
    <v-responsive>
      <media-image
        v-model="innerValue"
        resolution="original"
        class="cover-image"
        :background="false"
        height="100%"
      ></media-image>
    </v-responsive>
  </v-card>
</template>

<script>
import mediaImage from './media-image.vue'
export default {
  components: {
    mediaImage
  },
  props: {
    value: { type: Object, default: null }
  },
  data() {
    return {}
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style></style>
