import ReportsIndex from '@/views/report/index'
import MembersAccess from '@/views/report/members-access'
import UserAccess from '@/views/report/user-access'

export default [
  {
    path: '/reports',
    name: 'reports-index',
    component: ReportsIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/reports/members-access',
    name: 'members-access',
    component: MembersAccess,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/reports/:userId/user-access',
    name: 'user-access',
    component: UserAccess,
    meta: {
      authenticated: true
    }
  }
]
