export const addKnowledgeCheck = (client) => {
  client.knowledgeCheck = {
    fetch: (id) => {
      const p = `${client.__api.entityPath(client.__path, id)}/knowledgecheck`
      return client.get(p).then((response) => response.data)
    },
    put: (id, entity) => {
      const p = `${client.__api.entityPath(client.__path, id)}/knowledgecheck`
      return client.__api.put(p, entity).then((response) => response.data)
    }
  }
}
