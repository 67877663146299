import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const basePath = `${API_URL}/media`

const build = function (mediaId) {
  const path = `${basePath}/${mediaId}/versions`

  const client = apiClient.build(path)

  client.postFile = function (file, model) {
    const formData = new FormData()
    formData.append('file', file)

    return client.post(formData, model)
  }

  client.put = () => {} //no-op

  return client
}

export default { build }
