import axios from 'axios'
import router from '@/router'
import store from '@/store'
import AuthService from '@/services/auth.service'
import { API_URL } from '@/config'

const api = axios.create({
  baseURL: `${API_URL}`
})

const configureRequest = function (config) {
  return AuthService.getAccessToken().then((token) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return Promise.resolve(config)
  })
}

const reLogin = async () => {
  await AuthService.login(router.currentRoute.path)
}

const handle403 = async (err) => {
  if (!err.response || err.response.status !== 403) {
    return
  }

  if (err.config && err.config._ignoreAuth) {
    return
  }

  store.dispatch('messages/addError', {
    severity: 'danger',
    message: 'Unauthorized',
    error: err.response
  })

  localStorage.unauthorizedPath = router.currentRoute.path
  router.push('/unauthorized')
  return Promise.reject(err)
}

const handle401 = async (err) => {
  if (!err.response || err.response.status !== 401) {
    return
  }

  if (err.config) {
    if (err.config._ignoreAuth) {
      return
    }
    if (err.config._isRetry) {
      await reLogin()
    }
  }

  try {
    let user = await AuthService.getUser()

    if (user && user.expired) {
      user = await AuthService.refresh()
    }

    if (user) {
      err.config._isRetry = true
      await api(err.config)
    } else {
      await reLogin()
    }
  } catch (err) {
    await reLogin()
  }
}

const handleResponseErrors = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        store.dispatch('messages/addError', {
          severity: 'danger',
          message: 'Api error',
          error: err.response
        })
        break
      case 403:
        store.dispatch('messages/addError', {
          severity: 'danger',
          message: 'Forbidden',
          error: err.response
        })
        break
    }
  } else if (err.request) {
    if (localStorage) {
      localStorage.requestError = err
    }
    store.dispatch('messages/addError', {
      severity: 'danger',
      message: 'Api unavailable',
      error: err
    })
  }
}

api.interceptors.request.use(configureRequest, (err) => {
  return Promise.reject(err)
})

api.interceptors.request.use(function (config) {
  if (!config._ignoreTenant) {
    const tenantId = store.getters['user/organization'].tenantId
    const tenantUrl = `${config.baseURL}/${tenantId}`
    config.url = config.url.replace(config.baseURL, tenantUrl)
  }
  return config
})

api.interceptors.response.use(undefined, (err) => {
  handleResponseErrors(err)
  handle401(err)
  handle403(err)
  return Promise.reject({ ...err })
})

api.entityPath = function (path, id) {
  return `${path}/${id}`
}

api.build = function (p, config) {
  const path = p
  config = config || {}

  const del = (id) => {
    return api
      .delete(api.entityPath(path, id), config)
      .then((response) => response.data)
  }

  const fetch = (id, params) => {
    return api
      .get(api.entityPath(path, id), { ...config, ...params })
      .then((response) => response.data)
  }

  const get = (url, params) => {
    return api.get(url || path, { ...config, ...params }).then((response) => {
      let paging = {
        hasNextPage: false
      }

      if (response.data.paging) {
        paging = response.data.paging
      }

      return {
        paging: paging,
        data: response.data
      }
    })
  }

  const post = (entity) => {
    return api.post(path, entity, config)
  }

  const put = (id, entity) => {
    return api
      .put(api.entityPath(path, id), entity, config)
      .then((response) => response.data)
  }

  return {
    __api: api,
    __path: path,
    delete: del,
    fetch,
    get,
    post,
    put
  }
}

export default api
