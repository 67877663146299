<template>
  <div>
    <v-toolbar dense flat class="i-app-toolbar" color="darken-3">
      <v-toolbar-title>
        <v-breadcrumbs medium divider="/" :items="crumbs" class="pl-0">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
              :exact="true"
              class="text-lg-h6"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <slot></slot>
    </v-toolbar>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    crumbs: { Type: Array, default: [] }
  }
}
</script>

<style></style>
