<template>
  <v-row>
    <v-col>
      <v-chip
        v-if="value.status != 'published'"
        label
        small
        :color="statusColor"
        text-color="white"
        class="text-uppercase ma-1"
      >
        {{ value.status }}
      </v-chip>
      <v-chip
        v-if="value.status == 'published'"
        label
        small
        color="green"
        text-color="white"
        class="text-uppercase ma-1"
      >
        Published {{ value.publishDateTime | dateFormat }}
      </v-chip>
      <v-chip
        label
        small
        color="primary lighten-1"
        text-color="white"
        class="text-uppercase ma-1"
      >
        Version {{ value.version }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: null }
  },
  computed: {
    statusColor() {
      switch (this.value.status) {
        case 'draft':
          return 'red'
        case 'archived':
          return 'grey'
        default:
          return 'green'
      }
    }
  }
}
</script>

<style></style>
