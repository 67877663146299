var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-list',{attrs:{"title":"Media Library","to":{ name: 'media-create' },"to-label":"Add","headers":_vm.headers,"items":_vm.items,"paging":_vm.paging,"server-items-length":_vm.paging.totalPages},on:{"search":_vm.onSearch,"clear":_vm.onSearchClear,"page:changed":_vm.onPageChange,"sortBy:changed":_vm.onSortByChanged,"sortDesc:changed":_vm.onSortDescChanged,"row:view":_vm.view},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('strong',[_vm._v("Media Type:")]),_c('v-checkbox',{attrs:{"value":"image","label":"Images"},model:{value:(_vm.advancedFilters.mediaType),callback:function ($$v) {_vm.$set(_vm.advancedFilters, "mediaType", $$v)},expression:"advancedFilters.mediaType"}}),_c('v-checkbox',{attrs:{"value":"document","label":"Documents"},model:{value:(_vm.advancedFilters.mediaType),callback:function ($$v) {_vm.$set(_vm.advancedFilters, "mediaType", $$v)},expression:"advancedFilters.mediaType"}}),_c('v-checkbox',{attrs:{"value":"video","label":"Videos"},model:{value:(_vm.advancedFilters.mediaType),callback:function ($$v) {_vm.$set(_vm.advancedFilters, "mediaType", $$v)},expression:"advancedFilters.mediaType"}}),_c('v-checkbox',{attrs:{"value":"audio","label":"Audio"},model:{value:(_vm.advancedFilters.mediaType),callback:function ($$v) {_vm.$set(_vm.advancedFilters, "mediaType", $$v)},expression:"advancedFilters.mediaType"}})]},proxy:true},{key:"item.mediaType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.mediaType)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.version + ' / ' + item.status)+" ")])]}},{key:"item.mediaStatus",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.mediaStatus)+" ")])]}},{key:"item.eventDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.publishedDateTime))+" ")]}},{key:"item.createdDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.createdDateTime))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }