import axios from 'axios'
import { API_URL } from '@/config'
import store from '@/store'
import { cache } from './cache'
import queue from './queue'

import AuthService from '@/services/auth.service'

const path = `${API_URL}`

const imageClient = axios.create({
  baseURL: API_URL,
  responseType: 'arraybuffer'
})

imageClient.interceptors.request.use(async (config) => {
  const accessToken = await AuthService.getAccessToken()

  if (!accessToken) {
    throw new Error('A valid access token is required to query images')
  }

  config.headers.Authorization = `Bearer ${accessToken}`
  config.signal = queue.signal
  return config
})

/**
 * Returns a data url for a media item at a given resolution
 * @param {*} image
 * @param {*} resolution
 * @param {*} options
 * @returns
 */
const getDataUrl = async (image, resolution, options) => {
  const imagePath = `/media/${
    image.mediaId ?? image.mediaItemId ?? image.id
  }/${resolution}`
  return getFileAsDataUrl(imagePath, options)
}

const getBlob = async (image, resolution, options) => {
  options.binary = true
  const imagePath = `/media/${
    image.mediaId ?? image.mediaItemId ?? image.id
  }/${resolution}`
  return getFileAsDataUrl(imagePath, options, { responseType: 'blob' })
}

/**
 * Return a specific media file as a data url
 * @param {*} filePath
 * @param {*} options
 * @returns
 */
const getFileAsDataUrl = async (filePath, options, config) => {
  try {
    const tenantId = store.getters['user/organization'].tenantId
    const pathWithTenant = `/${tenantId}${filePath}`

    if (!options.noCache) {
      const cachedValue = await cache.fetch(pathWithTenant)
      if (cachedValue) {
        return cachedValue
      }
    }

    const url = `${path}${pathWithTenant}`
    options.noRedirects = true

    let response = await imageClient.get(url, {
      ...config,
      params: { ...options }
    })

    //
    // Handle manual redirects
    //
    if (response.status === 204 && response.headers['location']) {
      const location = response.headers['location']

      const redirectClient = axios.create({
        baseURL: location,
        responseType: 'arraybuffer'
      })

      response = await redirectClient.get(location, { ...config })
    }

    const contentType = response.headers['content-type']

    if (response.status === 200) {
      if (options.binary) {
        return response.data
      }

      const buffer = Buffer.from(response.data, 'binary')
      const base64 = buffer.toString('base64')
      const result = `data:${contentType};base64,${base64}`

      cache.put(pathWithTenant, result, contentType)
      return result
    }

    throw new Error(`Failed to load image, status: ${response.status}`)
  } catch (err) {
    if (axios.isCancel(err)) {
      //logger.info('Request for image cancelled', pathWithTenant)
      return ''
    } else {
      throw err
    }
  }
}

export { imageClient, getDataUrl, getFileAsDataUrl, getBlob }
