import { commentNode } from '../utils'
import policies from '@/services/policies'

const directive = function (el, binding, vnode) {
  const behavior = binding.modifiers.disable ? 'disable' : 'hide'
  const invert = binding.modifiers.not ? true : false
  const policy = binding.arg
  const resource = binding.value

  const result = policies.authorize(policy, resource)

  if ((!result && !invert) || (result && invert)) {
    if (behavior == 'hide') {
      commentNode(el, vnode)
    } else {
      el.disabled = true
    }
  }
}

export default directive
