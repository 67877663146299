import store from '@/store'

const handler = (roles) => {
  if (store.getters['user/isSysAdmin'] || store.getters['user/isOrgAdmin']) {
    return true
  }

  let result = false
  const a = Array.isArray(roles) ? roles : roles.split(',')

  for (let i = 0; i < a.length; i++) {
    if (store.getters['user/isInRole'](a[i])) {
      result = true
    }
  }

  return result
}

export default handler
