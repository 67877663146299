<template>
  <div>
    <h2>Unauthorized</h2>
    <v-alert prominent type="error">
      You do not have access to the requested resource.
      <div v-if="requestPath">
        <strong>Requested Path:</strong>{{ requestPath }}
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  computed: {
    requestPath() {
      return localStorage.unauthorizedPath
    }
  }
}
</script>

<style></style>
