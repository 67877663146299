<template>
  <v-card flat tile>
    <v-toolbar v-if="editable" dense elevation="2" color="grey lighten-4 mb-1">
      <v-toolbar-title>Upload Image</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="toggleCropTools">
        <v-icon>mdi-crop</v-icon>
      </v-btn>
    </v-toolbar>

    <v-toolbar v-if="toolbarAction == 'crop'" dense color="grey lighten-2">
      <v-spacer></v-spacer>

      <v-select
        label="Crop Sizes"
        :items="cropSizes"
        item-text="name"
        item-value="aspect"
        dense
        return-object
        single-line
        @change="onCropSizeChange"
        style="z-index: 120"
      >
      </v-select>
      <v-btn class="primary ma-2" @click="doCrop"> Crop </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <div class="image-container pa-2 mx-auto" :class="imageContainerClass">
        <img ref="img" :src="dataUrl" class="mx-auto" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import { media } from '@/services/api'

export default {
  props: {
    cropperOptions: {
      type: Object,
      default() {
        return {
          center: true,
          cropBoxResizable: true,
          dragMode: 'crop',
          guides: true,
          movable: true,
          responsive: true,
          rotatable: true,
          scalable: true,
          viewMode: 0,
          zoomable: true,
          zoomOnTouch: true
        }
      }
    },
    editable: { type: Boolean, default: false },
    value: { type: File, required: true },
    modalSize: { type: String, default: '' }
  },
  data() {
    return {
      cropper: null,
      cropSizes: [
        { name: 'cover', aspect: 2 / 1 },
        { name: 'thumbnail', aspect: 1 / 1 },
        { name: 'square', aspect: 1 / 1 },
        { name: 'wide', aspect: 16 / 9 },
        { name: 'tall', aspect: 9 / 16 },
        { name: 'free', aspect: null }
      ],
      dataUrl: null,
      file: null,
      image: null,
      toolbarAction: null
    }
  },
  computed: {
    imageContainerClass() {
      if (this.toolbarAction == 'crop') {
        return `cropper-container cropper-image-container-${this.$vuetify.breakpoint.name}`
      }
      return ''
    }
  },
  watch: {
    value(file) {
      this.loadImage(file)
    }
  },
  mounted() {
    this.loadImage(this.value)
  },
  methods: {
    loadImage(file) {
      if (file) {
        const fr = new FileReader()
        fr.onload = this.createImage
        fr.readAsDataURL(file)
      }
    },
    createImage(reader) {
      this.image = new Image()
      const dataUrl = reader.srcElement.result
      this.dataUrl = dataUrl
      this.image.src = dataUrl
    },
    toggleCropTools() {
      if (this.toolbarAction == 'crop') {
        this.toolbarAction = null
        this.cancelCrop()
      } else {
        this.toolbarAction = 'crop'
        this.createCropper()
      }
    },
    createCropper() {
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions)
      this.toolbarAction = 'crop'
    },
    cancelCrop() {
      this.toolbarAction = null
      this.cropper.destroy()
      this.cropper = null
    },
    doCrop() {
      const canvas = this.cropper.getCroppedCanvas()
      const dataUrl = canvas.toDataURL()
      this.dataUrl = dataUrl
      this.cropper.destroy()
      this.cropper = null
      this.toolbarAction = null

      this.$emit('input', media.api.dataURItoFile(dataUrl, this.value.name))
    },
    onCropSizeChange(size) {
      this.cropper.setAspectRatio(size.aspect)
    },
    destroyed() {
      if (this.dataUrl) {
        URL.revokeObjectURL(this.dataUrl)
      }
    }
  }
}
</script>

<style scoped="true" lang="scss">
.image-container {
  position: relative;
  background: #fff;
  max-width: 750px;
}

.image-container img {
  display: block;
  width: auto;
  max-width: 100%;
}

.cropper-container {
  z-index: 999;
}

.cropper-image-container-sm {
  height: 400px;
}

.cropper-image-container- {
  height: 300px;
}

.cropper-image-container-md {
  height: 547px;
}

.cropper-image-container-lg {
  height: 747px;
}
.cropper-image-container-xl {
  height: 1200px;
}
</style>
