<template>
  <div>
    <app-toolbar :crumbs="localCrumbs">
      <v-spacer></v-spacer>
      <knowledge-check-edit
        v-if="knowledgeCheck.id"
        v-model="knowledgeCheck"
        :api="componentsApi"
        @save="onSave"
      />
    </app-toolbar>

    <knowledge-check-detail
      v-if="model.id"
      v-model="knowledgeCheck"
      :entityType="'component'"
      :api="componentsApi"
      @save="onSave"
    />

    <div class="mt-2">
      <questions-table
        :componentId="componentId"
        :mediaOptions="mediaOptions"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { components as componentsApiBuilder } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import KnowledgeCheckDetail from '@/components/knowledgeCheck/detail.vue'
import KnowledgeCheckEdit from '@/components/knowledgeCheck/edit.vue'
import QuestionsTable from '@/components/knowledgeCheck/questions-table.vue'

export default {
  mixins: [contentPageMixin],
  components: {
    KnowledgeCheckDetail,
    KnowledgeCheckEdit,
    QuestionsTable
  },
  data() {
    return {
      knowledgeCheck: {},
      model: {},
      componentsApi: null
    }
  },
  computed: {
    localCrumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Knowledge Check',
        disabled: true
      })
      return items
    },
    mediaOptions() {
      return {
        showUpload: true,
        title: 'Media Selector',
        moduleId: this.moduleId,
        componentId: this.componentId,
        topicId: this.topicId,
        procedureId: this.procecdureId,
        entityId: this.componentId,
        entityType: 'component'
      }
    }
  },
  async mounted() {
    this.componentsApi = componentsApiBuilder.build(this.module.id)
    this.model = this.component
    this.knowledgeCheck = await this.componentsApi.knowledgeCheck.fetch(
      this.component.id
    )
  },
  watch: {
    component(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    onSave(model) {
      this.knowledgeCheck = { ...model }
    }
  }
}
</script>
