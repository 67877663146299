<template>
  <v-dialog v-model="dialog" width="auto">
    <v-card min-width="400">
      <v-app-bar flat>
        <v-toolbar-title>Version History</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-pagination="true"
        :hide-default-footer="true"
        class="elevation-1"
        disable-sort
      >
        <template v-slot:item.publishedDateTime="{ item }">
          {{ item.publishedDateTime | dateFormat }}
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    value: { type: Boolean, required: false },
    items: { type: Array, default: () => [] }
  },
  data() {
    return {
      dialog: false,
      headers: [
        { text: 'Version', value: 'version', width: '30%' },
        { text: 'Published Date', value: 'publishedDateTime', width: '70%' }
      ]
    }
  },
  watch: {
    value(val) {
      this.dialog = val
    },
    dialog(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    view(item) {
      this.$emit('view', item)
      this.dialog = false
    }
  }
}
</script>
