import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const basePath = `${API_URL}/groups`

const build = function (groupId) {
  const path = `${basePath}/${groupId}/members`

  const client = apiClient.build(path)

  return client
}

export default { build }
