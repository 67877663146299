<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <v-card flat tile>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <user-lookup @change="onUserSelected" />

              <v-alert v-if="user && !validUser" prominent type="error">
                <v-row align="center">
                  <v-col class="grow">
                    {{ user }} is not a valid IIP user. The user must first
                    create an IIP account.
                  </v-col>
                  <v-col class="shrink">
                    <v-btn :href="inviteUrl" color="primary">Invite User</v-btn>
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="isExistingMember">
            <v-col cols="12">
              <v-alert prominent type="info">
                <v-row align="center">
                  <v-col class="grow">
                    <strong>{{ user.value.email }} </strong> is already a member
                    of <strong>{{ organization.name }}</strong
                    >.
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      :to="{
                        name: 'member-detail',
                        params: { memberId: existingMember.id }
                      }"
                      color="secondary"
                      >View Member</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              All members can access content. Assign additional admin related
              roles below
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <roles-list v-model="model.roles" title="Additional Roles" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn
          tile
          large
          color="primary"
          @click.once="onSave"
          :disabled="!validUser || isExistingMember"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { members as membersApi } from '@/services/api'
import appToolbar from '@/components/layout/app-toolbar'
import rolesList from '@/components/users/roles'
import userLookup from '@/components/users/user-lookup'
import { IDENTITY_SERVER_URL } from '@/config'

export default {
  components: {
    appToolbar,
    rolesList,
    userLookup
  },
  data() {
    return {
      crumbs: [
        { text: 'Members', to: { name: 'member-index' } },
        { text: 'Create', disabled: true }
      ],
      model: {
        roles: []
      },
      user: null,
      isExistingMember: false,
      existingMember: null
    }
  },
  computed: {
    ...mapGetters('user', ['organization']),
    inviteUrl() {
      return `${IDENTITY_SERVER_URL}/Org/InviteCodes`
    },
    validUser() {
      return this.user && this.user.value
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.$router.go(-1)
    },
    async onUserSelected(val) {
      this.user = val
      if (this.user && this.user.value) {
        const response = await membersApi.get(null, {
          params: { email: this.user.value.email }
        })
        this.existingMember = response.data.results[0]
        this.isExistingMember = this.existingMember ? true : false
      } else {
        this.isExistingMember = false
      }
    },
    async onSave() {
      this.model.userId = this.user.value.id
      const response = await membersApi.post(this.model)

      this.addMessage({
        message: `Member '${response.data.name}' has been added as a member to ${this.organization.name}.`
      })

      this.$router.push({
        name: 'member-detail',
        params: { memberId: response.data.id }
      })
    }
  }
}
</script>

<style></style>
