<template>
  <v-img :src="alpaca" cover class="w-full h-full"></v-img>
</template>

<script>
import alpaca from '@/assets/alpaca.jpg'

export default {
  name: 'Help',
  data() {
    return {
      alpaca: alpaca
    }
  }
}
</script>
