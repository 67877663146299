const cacheAvailable = 'caches' in self

//
// Null cache in cases when cacheAvailable is false
//
export class NullCache {
  put(/*path, base64, contentType*/) {
    //no-op
  }
  async fetch(/*path*/) {
    return undefined
  }

  async delete() {
    return true
  }
}

//
// Browswer based cache
//
export class BrowserCache {
  _cache = null

  name = 'iip-media'

  constructor() {}

  async open() {
    if (this._cache == null) {
      this._cache = await caches.open(this.name)
    }

    return this._cache
  }

  async put(path, base64, contentType) {
    const cache = await this.open()

    const options = {
      headers: {
        'Content-Type': contentType
      }
    }

    const request = new Request(path)
    const response = new Response(base64, options)

    cache.put(request, response)
  }

  async fetch(path) {
    const cache = await this.open()

    const response = await cache.match(path)

    if (response) {
      return response.text()
    }

    return undefined
  }

  async delete() {
    return caches.delete(this.name)
  }
}

const cache = cacheAvailable ? new BrowserCache() : new NullCache()

export { cache }
