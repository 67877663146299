<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'

export default {
  components: {
    NodeViewWrapper
  },

  props: nodeViewProps,

  computed: {
    editable() {
      return this.editor.options.editable
    },
    iipOptions() {
      return this.editor.options.iipOptions
    }
  }
}
</script>
