import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import MediaExtension from './extensions/media/extension'
import ProcedureExtension from './extensions/procedures/extension'

export default {
  defaultDocument: {
    type: 'doc',
    content: []
  },
  extensions: [
    StarterKit,
    Placeholder.configure({
      placeholder: 'default content'
    }),
    MediaExtension.configure(),
    ProcedureExtension.configure(),
    TextAlign.configure({
      types: ['heading', 'paragraph']
    })
  ]
}
