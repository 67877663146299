<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <component-form
      :item="model"
      @cancel="onCancel"
      @save="onSave"
      :mediaSelectorOptions="mediaSelectorOptions"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import contentPageMixin from '@/components/mixins/content-page'
import coverImage from '@/components/mixins/cover-image'
import { components as componentApiBuilder } from '@/services/api'
import { media as mediaApi } from '@/services/api'
import componentForm from './form'

export default {
  mixins: [contentPageMixin, coverImage],
  components: {
    componentForm
  },
  data() {
    return {
      model: {},
      componentApi: null
    }
  },
  computed: {
    crumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Edit',
        disabled: true
      })
      return items
    },
    coverImage() {
      if (!this.model?.coverImageId) {
        return null
      }

      return `${this.model.mediaType}-preview`
    },
    mediaSelectorOptions() {
      return {
        entityId: this.component.id,
        entityType: 'component',
        moduleId: this.module.id,
        selected: this.component.coverImageId,
        coverApi: this.componentApi
      }
    }
  },
  async mounted() {
    this.componentApi = await componentApiBuilder.build(this.moduleId)
    if (this.component) {
      this.model = _.cloneDeep(this.component)
    }
  },
  watch: {
    component(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('context', ['clearComponent']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model, file) {
      await this.componentApi.put(
        model.id,
        _.pick(model, ['id', 'name', 'description'])
      )

      if (model.coverImageId) {
        await this.saveCoverImage(model, 'component')

        if (this.model.coverImageId != model.coverImageId) {
          await this.componentApi.cover(model.id, model.coverImageId)
        }
      } else if (file) {
        const media = {
          name: model.name,
          description: model.description,
          entityId: model.id,
          entityType: 'component'
        }

        const mediaResponse = await mediaApi.api.postFile(file, media)
        model.coverImage = mediaResponse.id
        model.coverImageId = mediaResponse.referenceId

        await this.componentApi.cover(model.id, model.coverImageId)
      }

      await this.clearComponent()

      this.$router.push({
        name: 'component-detail',
        params: { moduleId: this.moduleId, componentId: model.id }
      })
    }
  }
}
</script>

<style></style>
