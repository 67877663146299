import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const path = `${API_URL}/media`

const client = apiClient.build(path)

client.search = (params) => {
  const searchPath = `${path}/search`
  return client.__api.get(searchPath, params)
}

client.refs = (id, params) => {
  const refsPath = `${client.__api.entityPath(path, id)}/refs`
  return client.__api.get(refsPath, params).then((response) => response.data)
}

client.archive = (id) => {
  const archivePath = `${client.__api.entityPath(path, id)}/archive`
  return client.__api.put(archivePath).then((response) => response.data)
}

client.restore = (id) => {
  const archivePath = `${client.__api.entityPath(path, id)}/restore`
  return client.__api.put(archivePath).then((response) => response.data)
}

client.dataURItoBlob = (dataURI) => {
  const parts = dataURI.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const bytes = Buffer.from(parts[1], 'base64')

  const arrayBuffer = new ArrayBuffer(bytes.length)
  const _ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < bytes.length; i++) {
    _ia[i] = bytes[i]
  }

  const dataView = new DataView(arrayBuffer)
  const blob = new Blob([dataView], { type: contentType })
  return blob
}

client.dataURItoFile = (dataURI, filename) => {
  const blob = client.dataURItoBlob(dataURI)
  const file = new File([blob], filename, { type: blob.type })
  return file
}

client.postFile = function (file, model) {
  const formData = new FormData()
  formData.append('file', file)

  return client.post(formData, model)
}

client.post = function (formData, model) {
  for (const prop in model) {
    formData.append(prop, model[prop])
  }

  return client.__api.post(path, formData).then((response) => response.data)
}

export default client
