<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <group-module-form
      :value="model"
      :groups="groups"
      @cancel="onCancel"
      @save="onSave"
      @delete="onDelete"
    ></group-module-form>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import {
  groups as groupsApi,
  groupModules as groupModulesApiBuilder
} from '@/services/api'
import groupModuleForm from './form'
import { format } from '@/services/datetime'

export default {
  components: {
    appToolbar,
    groupModuleForm
  },
  data() {
    return {
      model: {},
      groups: null
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: 'Groups',
          to: { name: 'groups-index' },
          disabled: false
        }
      ]

      if (this.groups) {
        items.push({
          text: this.groups.name,
          to: {
            name: 'group-detail',
            params: { groupId: this.groupId }
          },
          disabled: false
        })
      }

      if (this.model) {
        items.push({ text: 'Edit Group Module', disabled: true })
      }

      return items
    },
    groupId() {
      return this.$route.params.groupId
    },
    groupModuleId() {
      return this.$route.params.groupModuleId
    }
  },
  async mounted() {
    this.groupModulesApi = groupModulesApiBuilder.build(this.groupId)
    this.group = await groupsApi.fetch(this.groupId)
    this.model = await this.groupModulesApi.fetch(this.groupModuleId)
    this.model.expiresFormatted = format.toLocal(
      this.model.expires,
      'mm/dd/yyyy'
    )
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.$router.push({
        name: 'group-detail',
        params: { groupId: this.groupId }
      })
    },
    async onSave(model) {
      await this.groupModulesApi.put(
        model.id,
        _.pick(model, ['permissions', 'expires', 'id'])
      )

      this.addMessage({
        message: 'Group Module has been updated.'
      })

      this.$router.push({
        name: 'group-detail',
        params: { groupId: this.groupId }
      })
    },
    async onDelete() {
      await this.groupModulesApi.delete(this.model.id)
      this.model.isDeleted = true
      this.$router.push({
        name: 'group-detail',
        params: { groupId: this.groupId }
      })
      this.addMessage({
        message: `Group Module '${this.model.moduleName}' has been deleted.`
      })
    }
  }
}
</script>

<style></style>
