import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const basePath = `${API_URL}/`

const fetch = async (mediaId, mediaReferenceId) => {
  const url = `${basePath}media/${mediaId}/refs/${mediaReferenceId}`
  return apiClient.get(url).then((response) => response.data)
}

const get = async (entity, params) => {
  let path = ''

  switch (entity.entityType) {
    case 'module':
      path = `modules/${entity.entityId}/media`
      break
    case 'component':
      path = `modules/${entity.moduleId}/components/${entity.entityId}/media`
      break
    case 'topic':
      path = `modules/${entity.moduleId}/components/${entity.componentId}/topics/${entity.entityId}/media`
      break
    default:
      path = `media/referencedby/${entity.entityId}`
  }

  return apiClient.get(`${basePath}${path}`, params)
}

const post = async (mediaId, model) => {
  return apiClient
    .post(`${basePath}media/${mediaId}/refs`, model)
    .then((response) => response.data)
}

const client = {
  fetch,
  get,
  post
}

export default client
