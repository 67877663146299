<template>
  <v-dialog v-model="dialog" width="75%" min-width="550px">
    <v-card>
      <v-app-bar flat>
        <v-toolbar-title>Version History</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <versions-table
        v-if="model"
        class="margin"
        :mediaId="model.id"
        :items="model.versions"
        :current="model.versionId"
      ></versions-table>
    </v-card>
  </v-dialog>
</template>

<script>
import versionsTable from './table.vue'
export default {
  components: {
    versionsTable
  },
  props: {
    value: { type: Boolean, required: false },
    model: { type: Object, required: true }
  },
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    value(val) {
      this.dialog = val
    }
  }
}
</script>
