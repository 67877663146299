import MediaIndex from '@/views/media/index.vue'
import MediaCreate from '@/views/media/create.vue'
import MediaDetail from '@/views/media/detail.vue'
import MediaEdit from '@/views/media/edit.vue'
import MediaVersionDetail from '@/views/media/versions/detail.vue'

export default [
  {
    path: '/media',
    name: 'media-index',
    component: MediaIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/media/new',
    name: 'media-create',
    component: MediaCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/media/:mediaId/edit',
    name: 'media-edit',
    component: MediaEdit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/media/:mediaId',
    name: 'media-detail',
    component: MediaDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/media/:mediaId/version/:versionId',
    name: 'media-version-detail',
    component: MediaVersionDetail,
    meta: {
      authenticated: true
    }
  }
]
