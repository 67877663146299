<template>
  <div>
    <app-toolbar :crumbs="crumbs" class="mb-2">
      <v-spacer></v-spacer>
      <v-btn
        v-if="!model.isDeleted"
        plain
        :to="{
          name: 'member-edit',
          params: { memberId: id }
        }"
      >
        Edit
      </v-btn>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item v-if="model" @click="moduleAccess">
              <v-list-item-content>
                <v-list-item-title>Module Access</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="model.isDeleted" @click="restore">
              <v-list-item-content>
                <v-list-item-title>Restore</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!model.isDeleted" @click="removeMember">
              <v-list-item-content>
                <v-list-item-title>Remove</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>
    <v-alert v-if="model.isDeleted" prominent type="warning">
      This member has been removed from this organization.
    </v-alert>
    <v-card flat tile>
      <v-card-text>
        <v-text-field label="Name" v-model="model.name" readonly></v-text-field>
        <v-text-field
          label="Email"
          v-model="model.email"
          readonly
        ></v-text-field>
        <roles-list v-model="model.roles" :disabled="true" />
        <groups-table :groups="groups" @viewGroup="viewGroup"></groups-table>
      </v-card-text>
    </v-card>

    <remove-dialog
      :name="model.name"
      v-model="removeDialog"
      @cancel="onCancel"
      @onRemove="onRemove"
    >
    </remove-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { members as membersApi } from '@/services/api'
import appToolbar from '@/components/layout/app-toolbar'
import rolesList from '@/components/users/roles'
import groupsTable from './components/groups-table'

import removeDialog from './remove-dialog'

export default {
  components: {
    appToolbar,
    rolesList,
    groupsTable,
    removeDialog
  },
  data() {
    return {
      model: {},
      groups: [],
      user: null,
      existingMember: false,
      removeDialog: false
    }
  },
  computed: {
    ...mapGetters('user', ['organization']),
    id() {
      return this.$route.params.memberId
    },
    crumbs() {
      const items = [{ text: 'Members', to: { name: 'member-index' } }]

      if (this.model) {
        items.push({ text: this.model.name, disabled: true })
      }

      return items
    }
  },
  watch: {
    id(val) {
      this.loadMember(val)
    }
  },
  mounted() {
    this.loadMember(this.id)
  },
  methods: {
    ...mapActions('messages', ['addMessage']),

    async loadMember(id) {
      this.model = await membersApi.fetch(id)
      this.groups = this.model.groups || []
    },
    removeMember() {
      this.removeDialog = true
    },
    onCancel() {
      this.removeDialog = false
    },
    async onRemove() {
      await membersApi.delete(this.id)
      this.$router.go(0)
    },
    async restore() {
      await membersApi.post(this.model)
      this.$router.go(0)
    },
    moduleAccess() {
      this.$router.push({
        name: 'user-access',
        params: {
          userId: this.model.userId,
          memberId: this.id,
          route: 'member'
        }
      })
    },
    viewGroup(item) {
      this.$router.push({
        name: 'group-detail',
        params: {
          groupId: item.id
        }
      })
    }
  }
}
</script>

<style></style>
