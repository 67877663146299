<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="formatted"
        v-model="formattedDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        clearable
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="dateSelected"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { format } from '@/services/datetime'

export default {
  props: {
    label: { type: String, default: 'Date' },
    value: { type: String, default: null }
  },
  data() {
    return {
      menu: false,
      date: null,
      formattedDate: null
    }
  },
  watch: {
    value(newVal) {
      this.parseValue(newVal)
    }
  },
  mounted() {
    this.parseValue(this.value)
  },
  methods: {
    parseValue(newVal) {
      this.formattedDate = format.toLocal(newVal, 'dd/mm/yyyy')
      if (!newVal) {
        this.date = null
      } else {
        this.formattedDate = newVal
      }
    },
    dateSelected() {
      this.menu = false
      this.formattedDate = format.toLocal(this.date, 'dd/mm/yyyy')
      this.$emit('input', this.formattedDate)
    },
    setFormattedDate() {
      if (!this.date) {
        this.formattedDate = null
        return
      }

      this.formattedDate = format.toLocal(this.date, 'dd/mm/yyyy')
    }
  }
}
</script>

<style></style>
