import { commentNode } from '../utils'
import store from '@/store'

const directive = function (el, binding, vnode) {
  const strict = binding.modifiers.strict ? true : false
  const disable = binding.modifiers.disable ? true : false

  const role = binding.arg
  //const roleString = binding.resource

  const result = store.getters['user/isInRoles'](role, strict)

  if (!result) {
    if (disable) {
      el.disabled = true
    } else {
      commentNode(el, vnode)
    }
  }
}

export default directive
