<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <v-card flat tile>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12">
              <memberSelector @change="onMemberChange" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn
          tile
          large
          color="primary"
          @click.once="onSave"
          :disabled="disabled"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import {
  groups as groupsApi,
  groupMembers as groupMembersApiBuilder
} from '@/services/api'
import memberSelector from '@/components/users/member-lookup'

export default {
  components: {
    appToolbar,
    memberSelector
  },
  data() {
    return {
      disabled: true,
      group: null,
      groupMembersApi: null,
      model: {}
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: 'Groups',
          to: { name: 'groups-index' },
          disabled: false
        }
      ]

      if (this.group) {
        items.push({
          text: this.group.name,
          to: {
            name: 'group-detail',
            params: { groupId: this.groupId }
          },
          disabled: false
        })
      }
      items.push({ text: 'Add Member', disabled: true })
      return items
    },
    groupId() {
      return this.$route.params.groupId
    }
  },
  async mounted() {
    this.groupMembersApi = groupMembersApiBuilder.build(this.groupId)
    this.group = await groupsApi.fetch(this.groupId)
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.$router.push({
        name: 'group-detail',
        params: { groupId: this.groupId }
      })
    },
    onMemberChange(member) {
      if (member) {
        this.model.memberId = member.id
        this.disabled = false
      } else {
        this.model.memberId = null
        this.disabled = true
      }
    },
    async onSave() {
      await this.groupMembersApi.post(this.model)

      this.addMessage({
        message: 'Group Member has been added.'
      })

      this.$router.push({
        name: 'group-detail',
        params: { groupId: this.groupId }
      })
    }
  }
}
</script>

<style></style>
