import ComponentIndex from '@/views/module/detail'
import ComponentCreate from '@/views/component/create'
import ComponentEdit from '@/views/component/edit'
import ComponentDetail from '@/views/component/detail'
import ComponentPublish from '@/views/component/publish'
import ComponentKnowlegeCheck from '@/views/component/knowledgecheck'

export default [
  {
    path: '/modules/:moduleId',
    name: 'component-index',
    component: ComponentIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/new',
    name: 'component-create',
    component: ComponentCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId',
    name: 'component-detail',
    component: ComponentDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/edit',
    name: 'component-edit',
    component: ComponentEdit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/publish',
    name: 'component-publish',
    component: ComponentPublish,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/knowledgecheck',
    name: 'component-knowledgecheck',
    component: ComponentKnowlegeCheck,
    meta: {
      authenticated: true
    }
  }
]
