import Help from '@/views/home/help.vue'
import Home from '@/views/home/index'
import Secure from '@/views/home/secure'
import Logout from '@/views/home/logout'
import Unauthorized from '@/views/home/unauthorized'

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/secure',
    name: 'Secure',
    component: Secure,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
    meta: {
      title: 'Help',
      allowAnonymous: true
    }
  }
]
