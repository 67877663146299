<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
    </app-toolbar>

    <v-row class="mt-0 pb-4">
      <v-col class="md-9 media-col">
        <template v-if="model && mediaComponent">
          <component
            :is="mediaComponent"
            v-model="model"
            height="auto"
            :no-cache="true"
            :file-path="filePath"
          />
        </template>
      </v-col>
      <v-col class="md-3">
        <v-card flat>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-subtitle>{{ model.name }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Description</v-list-item-title>
              <v-list-item-subtitle>{{
                model.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <!-- ToDo: add list of references to this specific version -->
  </div>
</template>

<script>
import { media } from '@/services/api'
import MediaImage from '@/components/media/media-image.vue'
import ContentPageMixin from '@/components/mixins/content-page.vue'

export default {
  mixins: [ContentPageMixin],
  components: {
    MediaImage
  },
  data() {
    return {
      filePath: null,
      model: {},
      versionsApi: null
    }
  },
  computed: {
    id() {
      return this.$route.params.versionId
    },
    mediaId() {
      return this.$route.params.mediaId
    },
    crumbs() {
      const items = [{ text: 'Media', to: { name: 'media-index' } }]
      if (this.model) {
        items.push({
          text: this.model.name,
          to: { name: 'media-detail', params: { mediaId: this.mediaId } }
        })
        items.push({ text: this.model.version })
      }

      return items
    },
    mediaComponent() {
      if (!this.model?.mediaType) {
        return null
      }

      return `${this.model.mediaType}-preview`
    }
  },
  watch: {
    id(newVal) {
      this.load(newVal)
    }
  },
  mounted() {
    if (this.id) {
      this.load(this.id)
    }
  },
  methods: {
    async load(id) {
      this.versionsApi = media.versions.build(this.mediaId)
      this.model = await this.versionsApi.fetch(id)
      const file = this.model.files.find((f) => f.isOriginal)
      if (file) {
        this.filePath = `/media/${this.mediaId}/versions/${this.id}/files/${file.id}`
      }
    }
  }
}
</script>
