<template>
  <div>
    <app-toolbar :crumbs="crumbs" class="mb-2"> </app-toolbar>

    <v-card flat tile>
      <v-card-text>
        Change the roles assigned to {{ model.name }}.
        <roles-list v-model="model.roles" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn tile large color="primary" @click.once="onSave"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { members as membersApi } from '@/services/api'
import appToolbar from '@/components/layout/app-toolbar'
import rolesList from '@/components/users/roles'

export default {
  components: {
    appToolbar,
    rolesList
  },
  data() {
    return {
      model: {},
      user: null,
      existingMember: false
    }
  },
  computed: {
    ...mapGetters('user', ['organization']),
    id() {
      return this.$route.params.memberId
    },
    crumbs() {
      const items = [{ text: 'Members', to: { name: 'member-index' } }]

      if (this.model) {
        items.push({ text: this.model.name, disabled: true })
      }

      return items
    }
  },
  watch: {
    id(val) {
      this.loadMember(val)
    }
  },
  mounted() {
    this.loadMember(this.id)
  },
  methods: {
    ...mapActions('messages', ['addMessage']),

    async loadMember(id) {
      this.model = await membersApi.fetch(id)
    },
    onCancel() {
      this.$router.go(-1)
    },
    async onSave() {
      const model = {
        roles: this.model.roles
      }

      await membersApi.put(this.id, model)

      this.addMessage({
        message: 'Member roles have been updated.'
      })

      this.$router.push({
        name: 'member-detail',
        params: { memberId: this.id }
      })
    }
  }
}
</script>

<style></style>
