<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
      <v-btn
        v-if="model.id"
        plain
        v-policy:module-update="module"
        :to="{
          name: 'procedure-edit',
          params: {
            moduleId: moduleId,
            componentId: componentId,
            procedureId: model.id
          }
        }"
      >
        Edit
      </v-btn>

      <v-menu bottom left offset-y pa-3>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item>
              <v-list-item-content @click="addStep">
                <v-list-item-title>Add Step</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="action in actions"
              :key="action.key"
              @click="selectedAction(action)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="action.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-policy:module-publish="module"></v-divider>
            <v-list-item
              v-if="
                (model.status === 'draft' && !model.isDeleted) ||
                (model.status === 'published' && !model.isDeleted)
              "
              v-policy:module-publish="module"
              @click="showArchive"
            >
              <v-list-item-content>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="module"
              @click="showRestore"
            >
              <v-list-item-content>
                <v-list-item-title>Restore</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="module"
              @click="showDelete"
            >
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <h2>{{ model.name }}</h2>

    <v-row class="mt-2">
      <v-col>
        <description :description.sync="model.description"></description>
      </v-col>
    </v-row>

    <div v-if="steps.length == 0 && !showStepModal">
      <p class="text-center">No steps, add the first step now</p>
    </div>

    <ol class="mt-2">
      <li v-for="step in model.steps" :key="step.id" :ref="step.id">
        <step-detail :step="step" @edit="editStep" @reorder="orderSteps" />
      </li>
    </ol>

    <div v-if="!showStepModal" class="text-center">
      <v-btn @click="addStep" color="primary" class="mb-3"> Add Step </v-btn>
      <v-btn @click="orderSteps" color="secondary" class="ml-3 mb-3">
        Order Steps
      </v-btn>
    </div>

    <step-modal
      :show.sync="showStepModal"
      :step="selectedStep"
      @save="saveStep"
      @cancel="cancelStep"
      @delete="deleteStep"
    />

    <stepOrder
      :show.sync="showStepOrder"
      :steps="model.steps"
      @save="saveOrder"
      @cancel="cancelOrder"
    />

    <versions-modal
      v-model="showVersions"
      :items="model.versions"
      @view="onViewVersion"
    />

    <archiveDialog
      :name="model.name"
      :unit="unit"
      v-model="archiveDialog"
      @cancel="onCancel"
      @archive="onArchive"
    >
      <p slot="text">
        All currently published data associated with this {{ unit }} will become
        unavailable.
      </p>
    </archiveDialog>

    <restoreDialog
      :name="model.name"
      :unit="unit"
      v-model="restoreDialog"
      @cancel="onCancel"
      @restore="onRestore"
    ></restoreDialog>

    <deleteDialog
      :name="model.name"
      :unit="unit"
      v-model="deleteDialog"
      @cancel="onCancel"
      @onDelete="onDelete"
    >
      <p slot="text">
        This procedure and media items not shared with other {{ unit }}s will
        also be deleted
      </p>
    </deleteDialog>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  procedures as proceduresApiBuilder,
  procedureSteps as stepsApiBuilder
} from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import versionsModal from '@/components/publishing/versions-modal.vue'
import stepDetail from './steps/detail.vue'
import stepModal from './steps/step-modal.vue'
import stepOrder from './steps/order-modal.vue'

export default {
  mixins: [contentPageMixin],
  components: {
    versionsModal,
    stepDetail,
    stepModal,
    stepOrder
  },
  data() {
    return {
      showStepModal: false,
      showStepOrder: false,
      unit: 'procedure',
      proceduresApi: null,
      stepsApi: null,
      model: {},
      actions: [
        {
          key: 'order',
          title: 'Order Steps',
          callback: () => {
            this.orderSteps()
          }
        },
        {
          key: 'knowledge-check',
          title: 'Knowledge Check',
          routeName: 'procedure-knowledgecheck'
        },
        {
          key: 'versions',
          title: 'Versions',
          callback: () => (this.showVersions = true)
        }
      ],
      showVersions: false,
      selectedStep: {}
    }
  },
  computed: {
    actionRouteParams() {
      return { procedureId: this.procedure.id }
    },
    archiveEnabled() {
      return this.actions.map((action) => {
        action.disabled =
          this.model.status === 3 && action.key === 'procedure-archive'
        return action
      })
    },
    steps() {
      return (this.model.steps ?? []).sort((a, b) => {
        return a.order - b.order
      })
    }
  },
  async mounted() {
    this.proceduresApi = proceduresApiBuilder.build(
      this.moduleId,
      this.componentId
    )

    if (this.procedure) {
      this.model = { ...this.procedure }
      this.stepsApi = stepsApiBuilder.build(
        this.moduleId,
        this.componentId,
        this.model.id
      )
    }
  },
  watch: {
    procedure(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    async loadProcedure(id) {
      this.model = await this.proceduresApi.fetch(id)
      this.stepsApi = stepsApiBuilder.build(
        this.moduleId,
        this.componentId,
        this.model.id
      )
    },
    async onArchive() {
      await this.proceduresApi.archive(this.procedureId)
      this.model.status = 'archived'
      this.loadProcedure(this.procedureId)
      this.archiveDialog = false
      this.addMessage({
        message: `Procedure '${this.model.name}' has been archived.`
      })
    },
    async onRestore() {
      await this.proceduresApi.restore(this.procedureId)
      this.model.status = 'draft'
      this.loadProcedure(this.procedureId)
      this.restoreDialog = false
      this.addMessage({
        message: `Procedure '${this.model.name}' has been restored.`
      })
    },
    async onDelete() {
      await this.proceduresApi.delete(this.procedureId)
      this.model.isDeleted = true
      this.deleteDialog = false
      this.$router.push({ name: 'procedure-index' })
      this.addMessage({
        message: `Procedure '${this.model.name}' has been deleted.`
      })
    },
    onViewVersion() {
      this.showVersions = false
    },
    orderSteps() {
      this.showStepOrder = true
    },
    async saveOrder(orderedSteps) {
      const steps = {}
      orderedSteps.map((s) => {
        steps[s.id] = s.order
      })

      const payload = { id: this.procedureId, steps }

      await this.proceduresApi.orderSteps(this.procedureId, payload)
      this.showStepOrder = false
      this.loadProcedure(this.procedureId)
    },
    cancelOrder() {
      this.showStepOrder = false
    },
    addStep() {
      this.showStepModal = true
    },
    editStep(step) {
      this.selectedStep = this.model.steps.find((s) => s.id === step.id)
      this.showStepModal = true
    },
    async saveStep(step) {
      console.log('saveStep', step)

      if (step.id) {
        await this.stepsApi.put(
          step.id,
          _.pick(step, ['id', 'name', 'description', 'content', 'order'])
        )
        this.selectedStep.name = step.name
        this.selectedStep.description = step.description
        this.selectedStep.content = step.content
        this.selectedStep.order= step.order
        this.selectedStep.mediaItems = step.mediaItems
      } else {
        if (!step.order) {
          const steps = this.model.steps
          step.order = steps.length + 1
          console.log('Number of steps: ', steps.length)
          console.log('step order is: ', step.order)
        }
        const response = await this.stepsApi.post(
          _.pick(step, ['name', 'description', 'order', 'content', 'order'])
        )

        this.model.steps.push(response.data)
        step.id = response.data.id
      }

      this.selectedStep = {}
      this.showStepModal = false

      this.$nextTick(() => {
        const element = this.$refs[step.id]
        const top = element[0].offsetTop
        window.scrollTo(0, top)
      })
    },
    async deleteStep(step) {
      await this.stepsApi.delete(step.id)
      this.model.steps = this.model.steps.filter((s) => s.id !== step.id)
      this.selectedStep = {}
      this.showStepModal = false
    },
    cancelStep() {
      this.selectedStep = {}
      this.showStepModal = false
    }
  }
}
</script>
