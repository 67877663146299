import '@mdi/font/css/materialdesignicons.css'
import 'typeface-roboto/index.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  options: {
    customProperties: true
  },
  theme: {
    options: {
      customProperties: true
    },
    light: true,
    themes: {
      light: {
        primary: '#284f71'
      }
    }
  }
})
