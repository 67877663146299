<template>
  <div>
    <v-row class="heading">
      <div class="text-h6">Modules</div>
      <v-spacer></v-spacer>
      <v-btn @click="addModule" plain> Add </v-btn>
    </v-row>
    <v-spacer></v-spacer>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="modules"
        :disable-pagination="true"
        :hide-default-footer="true"
        item-key="id"
        @click:row="viewModule"
        sort-by="moduleName"
      >
        <template v-slot:item.permission="{ item }">
          <span v-if="item.permission" style="text-transform: capitalize">{{
            item.permission
          }}</span>
        </template>
        <template v-slot:item.expires="{ item }">
          <span v-if="item.expires">{{
            new Date(item.expires).toLocaleDateString()
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    modules: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'moduleName', sortable: true },
        { text: 'Permisison', value: 'permission', sortable: true },
        { text: 'Expires', value: 'expires', width: '10%', sortable: true },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ]
    }
  },
  methods: {
    addModule() {
      this.$emit('addModule')
    },
    viewModule(item) {
      this.$emit('viewModule', item)
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
