<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <module-form
      :item="model"
      @cancel="onCancel"
      @save="onSave"
      :mediaSelectorOptions="mediaSelectorOptions"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import contentPageMix from '@/components/mixins/content-page'
import { modules as modulesApi } from '@/services/api'
import { media as mediaApi } from '@/services/api'
import moduleForm from './form'

export default {
  mixins: [contentPageMix],
  components: {
    moduleForm
  },
  data() {
    return {
      model: {
        coverImage: null
      }
    }
  },
  computed: {
    ...mapGetters('messages', ['errors']),
    mediaSelectorOptions() {
      return {
        entityId: this.module.id,
        entityType: 'module',
        moduleId: this.module.id,
        selected: this.module.coverImageId,
        coverApi: modulesApi
      }
    }
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model, file) {
      const response = await modulesApi.post(model)
      model = response.data

      if (file) {
        const media = {
          name: model.name,
          description: model.description,
          entityId: model.id,
          entityType: 'module'
        }

        const mediaResponse = await mediaApi.api.postFile(file, media)
        model.coverImage = mediaResponse.id
        model.coverImageId = mediaResponse.referenceId

        await modulesApi.cover(model.id, model.coverImageId)

        this.addMessage({
          message: `Module '${model.name}' has been created.`
        })
      }

      this.$router.push({
        name: 'module-detail',
        params: { moduleId: response.data.id }
      })
    }
  }
}
</script>
