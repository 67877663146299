<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <div v-if="model">
      <v-row no-gutters>
        <v-col class="md-9 pr-2 media-col">
          <v-toolbar flat dense color="grey lighten-4">
            <v-spacer></v-spacer>
            <media-upload-modal :accept="accept" @onUploaded="onFileUploaded" />
          </v-toolbar>

          <div v-if="!file && mediaComponent" class="text-center full-width">
            <component :is="mediaComponent" v-model="model" :no-cache="true" />
          </div>

          <div v-if="file && mediaForm">
            <component :is="mediaForm" v-model="file" :editable="false" />
          </div>
        </v-col>

        <v-col class="md-3 pa-2">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              label="Name"
              required
              v-model="model.name"
              :rules="nameRules"
            ></v-text-field>
            <v-textarea
              label="Description"
              auto-grow
              v-model="model.description"
              :rules="descriptionRules"
            ></v-textarea>
          </v-form>
        </v-col>
      </v-row>

      <v-card tile flat>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click.once="onCancel"> Cancel </v-btn>
          <v-btn
            tile
            large
            color="primary"
            @click="onSave"
            :disabled="!valid || saving"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import appToolbar from '@/components/layout/app-toolbar.vue'
import mediaUploadModal from '@/components/media/upload/modal.vue'
import mediaPage from '@/components/mixins/media-page.vue'
import { media } from '@/services/api'

export default {
  mixins: [mediaPage],
  components: {
    appToolbar,
    mediaUploadModal
  },
  data() {
    return {
      model: {},

      saving: false,
      valid: true,
      versionApi: null
    }
  },
  computed: {
    id() {
      return this.$route.params.mediaId
    },
    crumbs() {
      const result = [{ text: 'Media Library', to: { name: 'media-index' } }]

      if (this.model?.id) {
        result.push({
          text: this.model.name,
          to: { name: 'media-detail', params: { mediaId: this.model.id } }
        })
      }

      result.push({ text: 'Edit' })

      return result
    }
  },
  watch: {
    id(newVal) {
      this.load(newVal)
    }
  },
  mounted() {
    if (this.id) {
      this.load(this.id)
    }
  },
  methods: {
    async load(id) {
      this.model = await media.api.fetch(id)
      this.model.mediaId = this.model.id
      this.versionApi = media.versions.build(id)
    },
    onCancel() {
      this.$router.push({
        name: 'media-detail',
        params: { mediaId: this.id }
      })
    },
    async onSave() {
      await this.$refs.form.validate()

      this.saving = true

      if (this.valid) {
        await media.api.put(this.model.id, {
          name: this.model.name,
          description: this.model.description
        })

        if (this.file) {
          await this.versionApi.postFile(this.file, this.model)
        }

        await this.$router.push({
          name: 'media-detail',
          params: { mediaId: this.model.id }
        })
      }

      this.saving = false
    },
    async onFileUploaded(upload) {
      this.file = upload.file
      this.fileType = upload.fileType
    }
  }
}
</script>

<style></style>
