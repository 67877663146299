<template>
  <v-card flat tile outlined v-if="procedure" class="mx-auto text-left">
    <v-card-title>Procedure - {{ procedure.name }}</v-card-title>
    <v-card-text>
      <ol>
        <li v-for="step in procedure.steps" :key="step.id">
          <input type="checkbox" disabled="true" class="mx-1" />
          {{ step.name }}
        </li>
      </ol>
    </v-card-text>
  </v-card>
</template>

<script>
import { procedures as procedureApiBuilder } from '@/services/api'

export default {
  props: {
    procedureId: { type: String, require: true },
    iipOptions: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      procedure: null,
      procedureApi: null
    }
  },
  watch: {
    procedureId(val) {
      this.load(val)
    }
  },
  mounted() {
    this.procedureApi = procedureApiBuilder.build(
      this.iipOptions.moduleId,
      this.iipOptions.componentId
    )
    this.load(this.procedureId)
  },
  methods: {
    async load(id) {
      const procedure = await this.procedureApi.fetch(id)

      procedure.steps = procedure.steps.sort((a, b) => a.order - b.order)
      this.procedure = procedure
    }
  },
  view() {
    alert('view details of procedure')
  }
}
</script>

<style></style>
