<template>
  <v-list-item>
    <v-list-item-icon>
      <v-icon v-if="rule.succeeded" dense medium color="green darken-2">
        mdi-check-circle
      </v-icon>
      <v-icon v-if="!rule.succeeded" dense medium color="red darken-2">
        mdi-alert-box
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="rule.name"></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    rule: { type: Object, required: true }
  }
}
</script>

<style></style>
