import filters from '../filters'
import authorize from '@/components/auth/authorize'
import policy from '@/components/auth/authorize-policy'
import role from '@/components/auth/authorize-role'

import defaultNavMenu from '@/components/layout/nav-drawer/default-menu.vue'
import systemNavMenu from '@/components/layout/nav-drawer/system-menu.vue'

import audioPreview from '@/components/media/audio/audio-preview.vue'
import documentPreview from '@/components/media/document/document-preview.vue'
import imagePreview from '@/components/media/image/image-preview.vue'
import videoPreview from '@/components/media/video/video-preview.vue'

import audioForm from '@/components/media/audio/audio-form.vue'
import documentForm from '@/components/media/document/document-form.vue'
import imageForm from '@/components/media/image/image-form.vue'
import videoForm from '@/components/media/video/video-form.vue'

const install = function (Vue) {
  Vue.use(filters)

  Vue.directive('policy', policy)
  Vue.directive('role', role)

  Vue.component('authorize', authorize)

  Vue.component('audio-preview', audioPreview)
  Vue.component('document-preview', documentPreview)
  Vue.component('image-preview', imagePreview)
  Vue.component('video-preview', videoPreview)

  Vue.component('audio-form', audioForm)
  Vue.component('document-form', documentForm)
  Vue.component('image-form', imageForm)
  Vue.component('video-form', videoForm)

  Vue.component('default-nav-menu', defaultNavMenu)
  Vue.component('system-nav-menu', systemNavMenu)
}

export default { install }
