<script>
export default {
  data() {
    return {
      file: null,
      fileType: null,
      model: null,
      descriptionRules: [(v) => !!v || 'Description is required'],
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 250) || 'Name must be less than 250 characters'
      ]
    }
  },
  computed: {
    accept() {
      switch (this.model?.mediaType) {
        case 'image':
          return 'image/*'
        case 'audio':
          return 'audio/*'
        case 'video':
          return 'video/*'
        case 'document':
          return '.pdf'
      }

      return 'image/*,audio/*,video/*,.pdf'
    },
    mediaComponent() {
      if (!this.model?.mediaType) {
        return null
      }

      return `${this.model.mediaType}-preview`
    },
    mediaForm() {
      if (!this.fileType) {
        return null
      }

      return `${this.fileType}-form`
    }
  }
}
</script>
