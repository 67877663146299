<template>
  <div v-if="text">
    <div v-html="text"></div>
  </div>
</template>

<script>
import { markdownToHtml } from '@/services/text'
export default {
  props: {
    value: { type: String, default: '' }
  },
  data() {
    return {
      text: ''
    }
  },
  watch: {
    value(val) {
      this.parseValue(val)
    }
  },
  mounted() {
    this.parseValue(this.value)
  },
  methods: {
    parseValue(val) {
      this.text = markdownToHtml(val)
    }
  }
}
</script>
