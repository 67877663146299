import api from './api'
import * as cache from './cache'
import * as files from './files'
import * as placeholder from './placeholder'
import versions from './versions'

import references from './references'

export default {
  api,
  cache,
  placeholder,
  files,
  references,
  versions
}
