<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :color="color" dark plain v-bind="attrs" v-on="on">
        {{ label }}
        <v-icon v-if="icon" small class="mr-2" :title="title">{{
          icon
        }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-btn color="error" plain @click="onCancel">
          {{ cancelText }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn tile large color="primary" @click="onConfirm">
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    color: { type: String, default: 'error' },
    label: { type: String, default: 'delete' },
    icon: { type: String, default: null },
    title: { type: String, default: 'Confirm' },
    text: { type: String, default: 'Are you sure?' },
    confirmText: { type: String, default: 'Yes, Delete' },
    cancelText: { type: String, default: 'Cancel' }
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    onConfirm() {
      this.$emit('confirm')
      this.dialog = false
    },
    onCancel() {
      this.$emit('cancel')
      this.dialog = false
    }
  }
}
</script>

<style></style>
