import OrganizationIndex from '@/views/system/organization/index'
import OrganizationCreate from '@/views/system/organization/create'
import OrganizationEdit from '@/views/system/organization/edit'
import OrganizationDetail from '@/views/system/organization/detail'

export default [
  {
    path: '/system/organizations',
    name: 'organization-index',
    component: OrganizationIndex,
    meta: {
      authenticated: true,
      system: true
    }
  },
  {
    path: '/system/organizations/new',
    name: 'organization-create',
    component: OrganizationCreate,
    meta: {
      authenticated: true,
      system: true
    }
  },
  {
    path: '/system/organizations/:organizationId',
    name: 'organization-detail',
    component: OrganizationDetail,
    meta: {
      authenticated: true,
      system: true
    }
  },
  {
    path: '/system/organizations/:organizationId/edit',
    name: 'organization-edit',
    component: OrganizationEdit,
    meta: {
      authenticated: true,
      system: true
    }
  }
]
