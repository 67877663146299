<template>
  <div class="home">
    <v-card class="d-flex justify-center mb-3 mt-6" flat tile>
      IIP CMS Home
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {}
  },

  methods: {}
}
</script>
