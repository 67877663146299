<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <div style="margin: 15px"></div>
    <v-row>
      <!-- <v-icon>mdi-card-account-details-outline </v-icon> -->
      <router-link class="navigation" :to="{ name: 'members-access' }">
        Member Access Report
      </router-link>
    </v-row>
  </div>
</template>

<script>
import appToolbar from '@/components/layout/app-toolbar'

export default {
  components: {
    appToolbar
  },
  data() {
    return {
      crumbs: [
        { text: 'Reports' }
        //{ text: 'Reports', to: { name: 'module-index' } },
        //{ text: 'Edit', disabled: true }
      ]
    }
  }
}
</script>

<style scoped>
.navigation {
  padding: 15px;
}
</style>
