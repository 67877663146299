<template>
  <div>
    <v-row class="heading">
      <div class="text-h6">Group Membership</div>
    </v-row>
    <v-spacer></v-spacer>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="groups"
        item-key="id"
        @click:row="viewGroup"
        :disable-pagination="true"
        :hide-default-footer="true"
      >
        <template v-slot:item.actions="{ item }">
          <div class="text-right">
            <v-icon small class="mr-2" @click="view(item)" title="view group"
              >mdi-square-edit-outline</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    groups: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Description', value: 'description', sortable: false },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ]
    }
  },
  methods: {
    viewGroup(item) {
      this.$emit('viewGroup', item)
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
