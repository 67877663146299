import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import IipMediaItem from './component.vue'

export const inputRegex = /^\s*(#media:(\S*)?)\s$/

export default Node.create({
  name: 'iip-media',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      caption: {
        default: 'Media Item'
      },
      mediaId: {
        default: null
      },
      mediaReferenceId: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iip-media'
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['iip-media', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(IipMediaItem)
  },

  addCommands() {
    return {
      insertMedia:
        () =>
          ({ chain }) => {
            return chain()
              .insertContent(
                '<iip-media caption="image caption" mediaId="" mediaReferenceId=""></iip-media>'
              )
              .run()
          }
    }
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: (match) => {
          const [, , caption] = match
          return { caption }
        }
      })
    ]
  }
})
