import TopicIndex from '@/views/component/detail'
import TopicCreate from '@/views/topic/create'
import TopicEdit from '@/views/topic/edit'
import TopicPublish from '@/views/topic/publish'
import TopicDetail from '@/views/topic/detail'
import TopicKnowledgeCheck from '@/views/topic/knowledgecheck'

export default [
  {
    path: '/modules/:moduleId/components/:componentId',
    name: 'topic-index',
    component: TopicIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/topic/new',
    name: 'topic-create',
    component: TopicCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/topic/:topicId',
    name: 'topic-detail',
    component: TopicDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/topic/:topicId/edit',
    name: 'topic-edit',
    component: TopicEdit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/topic/:topicId/publish',
    name: 'topic-publish',
    component: TopicPublish,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/component/:componentId/topic/:topicId/knowledgecheck',
    name: 'topic-knowledgecheck',
    component: TopicKnowledgeCheck,
    meta: {
      authenticated: true
    }
  }
]
