<template>
  <div>
    <media-image
      v-model="image"
      :height="height"
      :resolution="resolution"
      :no-cache="noCache"
      :file-path="filePath"
    />
  </div>
</template>

<script>
import MediaImage from '@/components/media/media-image.vue'

export default {
  components: {
    MediaImage
  },
  props: {
    filePath: { type: String, default: null },
    value: { type: Object, default: null },
    height: { type: String, default: 'auto' },
    maxHeight: { type: String, default: '550px' },
    resolution: { type: String, default: 'original' },
    noCache: { type: Boolean, default: false }
  },
  data() {
    return {
      image: null
    }
  },
  watch: {
    value(v) {
      this.image = v
    }
  },
  mounted() {
    this.image = this.value
  },
  destroyed() {
    if (this.dataUrl) {
      URL.revokeObjectURL(this.dataUrl)
    }
  }
}
</script>
