import store from '@/store'

const handler = (module, operation) => {
  if (!module) {
    return false
  }

  if (store.getters['user/isSysAdmin'] || store.getters['user/isOrgAdmin']) {
    return true
  }

  let result = false

  switch (operation) {
    case 'create':
      result = store.getters['user/isInRoles']([
        'content-creator',
        'content-publisher'
      ])
      break
    default:
      result = hasModulePermission(module, operation)
      break
  }
  return result
}

//
// Check if a user has a permission to access a specific module
// If the module as no groups assigned then fall back to the user's top level roles
//
function hasModulePermission(module, permission) {
  if (module.groups && module.groups.length > 0) {
    return hasModuleGroupPermission(module, permission)
  }

  let result = true
  switch (permission) {
    case 'write':
      result = store.getters['user/isInRoles']([
        'content-creator',
        'content-publisher'
      ])
      break
    case 'publish':
      result = store.getters['user/isInRoles'](['content-publisher'])
      break
    default:
      result = true
  }

  return result
}

//
// Check if user is a member of a group assigned to a module
// and if the group has permission to perform the requested operation
//
function hasModuleGroupPermission(module, permission) {
  let result = false

  const userGroups = store.getters['user/groups']

  for (let i = 0; i < module.groups.length; i++) {
    if (userGroups.includes(module.groups[i].groupId)) {
      switch (permission) {
        case 'write':
          result = ['write', 'publish'].includes(module.groups[i].permissions)
          break
        case 'publish':
          result = module.groups[i].permissions == 'publish'
          break
      }

      if (result) {
        break
      }
    }
  }

  return result
}

export default handler
