<template>
  <div>
    <div v-if="!isValididated" class="text-center">
      <h4>Validating</h4>
      <v-progress-circular indeterminate color="green"></v-progress-circular>
    </div>

    <div v-if="isValididated">
      <h2>{{ headerMessage }}</h2>

      <v-card tile>
        <v-list dense>
          <v-card-title>Rules</v-card-title>
          <v-list-item-group v-model="selectedRule" color="primary">
            <rule-result
              v-for="(r, i) in validation.rules"
              :key="i"
              :rule="r"
            />
          </v-list-item-group>
        </v-list>
      </v-card>

      <v-card tile class="mt-2" v-if="hasChildren">
        <v-card-title>{{ childLabel }}</v-card-title>
        <v-card-text>
          <v-treeview activatable dense open-all :items="validation.children">
            <template v-slot:label="{ item, leaf }">
              {{ item.name }} {{ getFailedRules(item) }}
              <v-btn
                v-if="!item.succeeded"
                icon
                @click="onItemClicked({ item, leaf })"
              >
                <v-icon small> mdi-link-variant </v-icon>
              </v-btn>
            </template>
            <template v-slot:prepend="{ item }">
              <v-icon v-if="item.succeeded" dense medium color="green darken-2">
                mdi-check-circle
              </v-icon>
              <v-icon v-else dense medium color="red darken-2">
                mdi-alert-box
              </v-icon>
              <v-icon
                v-if="item.status == 'draft'"
                dense
                medium
                color="yellow darken-2"
                title="new draft item"
              >
                mdi-alert-decagram
              </v-icon>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>

      <v-card tile class="mt-2" v-if="hasProcedures">
        <v-card-title>Procedures</v-card-title>
        <v-card-text>
          <v-treeview activatable dense open-all :items="validation.procedures">
            <template v-slot:label="{ item, leaf }">
              {{ item.name }} {{ getFailedRules(item) }}
              <v-btn
                v-if="!item.succeeded"
                icon
                @click="onItemClicked({ item, leaf })"
              >
                <v-icon small> mdi-link-variant </v-icon>
              </v-btn>
            </template>
            <template v-slot:prepend="{ item }">
              <v-icon v-if="item.succeeded" dense medium color="green darken-2">
                mdi-check-circle
              </v-icon>
              <v-icon v-else dense medium color="red darken-2">
                mdi-alert-box
              </v-icon>
              <v-icon
                v-if="item.status == 'draft'"
                dense
                medium
                color="yellow darken-2"
                title="new draft item"
              >
                mdi-alert-decagram
              </v-icon>
            </template>
          </v-treeview>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import ruleResult from './rule-result'

export default {
  components: {
    ruleResult
  },
  props: {
    childLabel: { type: String, default: 'Children' },
    validation: { type: Object, default: null }
  },
  data() {
    return {
      selectedRule: null
    }
  },
  computed: {
    headerMessage() {
      if (this.isValid) {
        return 'Ready to Publish'
      }

      return 'Fix Errors Before Publishing'
    },
    isValididated() {
      return this.validation != null
    },
    isValid() {
      return this.validation && this.validation.succeeded
    },
    children() {
      return this.validation ? this.validation.children : []
    },
    procedures() {
      return this.validation ? this.validation.procedures : []
    },
    hasChildren() {
      return this.children && this.children.length > 0
    },
    hasProcedures() {
      return this.procedures && this.procedures.length > 0
    }
  },
  methods: {
    getFailedRules(item) {
      if (item.succeeded) {
        return ''
      }

      return item.rules
        .filter((r) => !r.succeeded)
        .reduce((msg, rule) => `${msg} ${rule.name}`, '- Failed: ')
    },
    onItemClicked(evt) {
      this.$emit('click', evt.item)
    }
  }
}
</script>
