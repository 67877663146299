<template>
  <div>
    <page-list
      title="Members"
      :to="{ name: 'member-create' }"
      to-label="Add"
      @search="onSearch"
      @clear="onSearchClear"
      :headers="headers"
      :items="items"
      :paging="paging"
      @page:changed="onPageChange"
      @row:view="view"
      :filterCount="filterCount"
    >
      <template v-slot:filters>
        <v-checkbox
          v-model="advancedFilters"
          value="1"
          label="Include deleted members"
          @click="includeAll = !includeAll"
        ></v-checkbox>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-right text-decoration-none">
          <v-icon small class="mr-2" @click="view(item)" title="view member"
            >mdi-square-edit-outline</v-icon
          >
        </div>
      </template>
    </page-list>
  </div>
</template>

<script>
import { members as MembersApi } from '@/services/api'
import pageList from '@/components/layout/page-list.vue'

export default {
  components: {
    pageList
  },
  data() {
    return {
      items: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'User Code', value: 'userCode' },
        { text: 'Email', value: 'email' },
        { text: 'Status', value: 'userStatus' },
        { text: 'Roles', value: 'roles' },
        { text: '', value: 'actions', sortable: false }
      ],
      advancedFilters: [],
      includeAll: false,
      loaded: false,
      paging: {},
      search: ''
    }
  },
  computed: {
    filterCount() {
      return this.advancedFilters.reduce(function (a, b) {
        return parseInt(a) + parseInt(b)
      }, 0)
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const response = await MembersApi.get(null, {
        params: {
          term: this.search,
          page: this.paging.currentPage,
          includeAll: this.includeAll
        }
      })

      this.paging = response.data.meta.paging
      this.items = response.data.results
      this.loaded = true
    },
    onPageChange(pagination) {
      if (this.loaded) {
        this.paging.currentPage = pagination.page
        this.load()
      }
    },
    onSearchClear() {
      this.onSearch(null)
    },
    onSearch(term) {
      this.search = term
      this.paging.currentPage = 1
      this.load()
    },
    view(item) {
      this.$router.push({
        name: 'member-detail',
        params: { memberId: item.id }
      })
    }
  }
}
</script>

<style></style>
