<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
    </app-toolbar>

    <version-chips v-model="model"></version-chips>

    <v-alert v-if="model.status == 'published'" type="warning">
      This version is already published
    </v-alert>
    <validation-result :validation="validation" />

    <v-card flat tile class="mt-2">
      <v-card-actions>
        <v-btn
          v-if="model.id"
          icon
          color="default"
          aria-label="refresh"
          @click="onRefresh"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="model.id"
          plain
          color="default"
          :to="{
            name: 'topic-detail',
            params: {
              moduleId: moduleId,
              componentId: componentId,
              topicId: model.id
            }
          }"
        >
          Cancel
        </v-btn>
        <v-btn
          tile
          large
          color="primary"
          @click.once="onPublish"
          :disabled="!canPublish"
        >
          Publish
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import { topics as topicsApiBuilder } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import validationResult from '@/components/publishing/validation-result'

export default {
  mixins: [contentPageMixin],
  components: {
    validationResult
  },
  data() {
    return {
      model: {},
      validation: {},
      isValididated: false,
      topicsApi: null
    }
  },
  computed: {
    canPublish() {
      return this.model.status == 'draft' && this.validation.succeeded
    }
  },
  async mounted() {
    this.topicsApi = topicsApiBuilder.build(this.module.id, this.component.id)
    this.model = this.topic
    this.validate()
  },
  watch: {
    topic(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('context', ['clearComponent']),
    async validate() {
      this.isValididated = false
      this.validation = await this.topicsApi.validate(this.topic.id)
      this.isValididated = true
    },
    async onPublish() {
      this.loading = true

      const response = await this.topicsApi.publish(this.topic.id)

      this.addMessage({
        message: `Topic '${this.model.name}' version ${response.version} has been published.`
      })

      const routeParams = {
        moduleId: this.moduleId,
        componentId: this.componentId
      }

      this.clearComponent()

      this.$router.push({
        name: 'component-detail',
        params: routeParams
      })
      this.loading = false
    },
    async onRefresh() {
      this.loading = true
      await this.validate()
      this.loading = false
    }
  }
}
</script>

<style scoped>
.card {
  margin: 0px 15px;
}
.procedure {
  margin-left: 15px;
}
</style>
