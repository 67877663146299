<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <group-module-form
      v-model="model"
      :group="group"
      @cancel="onCancel"
      @save="onSave"
    ></group-module-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import {
  groups as groupsApi,
  groupModules as groupModulesApiBuilder
} from '@/services/api'
import groupModuleForm from './form'

export default {
  components: {
    appToolbar,
    groupModuleForm
  },
  data() {
    return {
      group: null,
      groupModulesApi: null,
      model: {}
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: 'Groups',
          to: { name: 'groups-index' },
          disabled: false
        }
      ]

      if (this.group) {
        items.push({
          text: this.group.name,
          to: {
            name: 'group-detail',
            params: { groupId: this.groupId }
          },
          disabled: false
        })
      }
      items.push({ text: 'Add Group Module', disabled: true })
      return items
    },
    groupId() {
      return this.$route.params.groupId
    }
  },
  async mounted() {
    this.groupModulesApi = groupModulesApiBuilder.build(this.groupId)
    this.group = await groupsApi.fetch(this.groupId)
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.$router.push({
        name: 'group-detail',
        params: { groupId: this.groupId }
      })
    },
    async onSave(model) {
      await this.groupModulesApi.post(model)

      this.addMessage({
        message: 'Group Module has been created.'
      })

      this.$router.push({
        name: 'group-detail',
        params: { groupId: this.groupId }
      })
    }
  }
}
</script>

<style></style>
