<template>
  <node-view-wrapper class="media-component" color="grey lighten-4">
    <div v-if="editable" class="editor-content">
      <div
        v-if="!procedure"
        class="d-flex pa-2 align-content-center justify-center full"
      >
        <v-btn @click="showSelect = !showSelect">Select Procedure</v-btn>
      </div>

      <div v-if="procedure" @click="showSelect = !showSelect">
        <v-hover v-slot="{ hover }" open-delay="100">
          <v-card
            :elevation="hover ? 16 : 0"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
          >
            <v-card-text>
              <procedure-detail
                :procedure-id="procedure.id"
                :iip-options="iipOptions"
              />
            </v-card-text>
          </v-card>
        </v-hover>
      </div>

      <procedure-selector
        v-model="showSelect"
        :options="iipOptions"
        @selected="onProcedureSelected"
      />
    </div>

    <div v-if="!editable && procedure" class="content">
      <procedure-detail
        :procedure-id="procedure.id"
        :iip-options="iipOptions"
      />
    </div>
  </node-view-wrapper>
</template>

<script>
import baseComponent from '@/components/tiptap/extensions/base-component'
import procedureSelector from '@/components/procedures/procedure-selector.vue'
import procedureDetail from '@/components/procedures/procedure-detail.vue'

export default {
  mixins: [baseComponent],
  components: {
    procedureDetail,
    procedureSelector
  },
  data() {
    return {
      showSelect: false,
      valid: true,
      procedure: null
    }
  },
  computed: {
    pos() {
      return this.getPos()
    }
  },
  mounted() {
    if (this.node.attrs.procedureId) {
      this.procedure = {
        id: this.node.attrs.procedureId
      }
    }
  },
  methods: {
    onProcedureSelected(item) {
      this.updateAttributes({
        procedureId: item.id
      })

      this.procedure = {
        id: item.id
      }

      this.showSelect = false

      this.editor.commands.focus(this.getPos() + 1)
    }
  }
}
</script>

<style lang="scss">
.content {
  margin-top: 1.5rem;
  padding: 1rem;
}

.editor-content {
  margin-top: 1.5rem;
  padding: 1rem;
}
</style>
