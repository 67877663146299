import GroupModulesIndex from '@/views/group/detail'
import GroupModuleCreate from '@/views/group-module/create'
import GroupModuleEdit from '@/views/group-module/edit'

export default [
  {
    path: '/groups/:groupId',
    name: 'group-modules-index',
    component: GroupModulesIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/groups/:groupId/modules/new',
    name: 'group-module-create',
    component: GroupModuleCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/groups/:groupId/moduels/:groupModuleId',
    name: 'group-module-edit',
    component: GroupModuleEdit,
    meta: {
      authenticated: true
    }
  }
]
