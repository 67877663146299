<template>
  <div>
    <v-alert v-if="!model.isEnabled" text type="warning">
      <h5>Knowledge Check is disabled.</h5>
      You can still edit the knowledge check, but it will not be available for
      users to complete.
    </v-alert>
    <div>
      <v-alert v-if="model.isEnabled" text type="info">
        <h5>Enabled</h5>
        Knowledge checks are enabled for this item.
      </v-alert>
      <v-row v-if="model.instructions">
        <v-col>
          <h5>Instructions</h5>
          <viewer v-model="model.instructions" />
        </v-col>
      </v-row>
      <v-row v-if="model.numberOfQuestions">
        <v-col>
          <h5>Number of Questions</h5>
          {{ model.numberOfQuestions }}
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Viewer from '@/components/text/viewer.vue'

export default {
  components: {
    Viewer
  },
  props: {
    value: { type: Object, required: true },
    entityType: { type: String, required: true },
    api: { type: Object, required: true }
  },
  data() {
    return {
      model: {},
      valid: true,
      numberRules: [
        (v) => !!v || 'Number of questions is required',
        (v) => v > 0 || `Number of questions must be more than ${v}`
      ]
    }
  },
  watch: {
    value(val) {
      this.model = _.clone(val)
    }
  },
  mounted() {
    this.model = _.clone(this.value)
  }
}
</script>
