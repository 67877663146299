<template>
  <div>
    <v-row class="heading">
      <div class="text-h6">Members</div>
      <v-spacer></v-spacer>
      <v-btn @click="addMember" plain> Add </v-btn>
    </v-row>
    <v-spacer></v-spacer>
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="members"
        :disable-pagination="true"
        :hide-default-footer="true"
        sort-by="fullName"
        item-key="id"
        @click:row="viewMember"
      >
        <template v-slot:item.actions="{ item }">
          <div class="text-right">
            <confirmation-dialog
              label=""
              icon="mdi-delete-outline"
              title="Remove Member"
              text="Are you sure you want to remove this member from the group?"
              color="primary"
              confirmText="Yes, Remove"
              @confirm="removeMember(item)"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { groupMembers as groupMembersApiBuilder } from '@/services/api'
import confirmationDialog from '@/components/dialogs/confirmation'

export default {
  components: {
    confirmationDialog
  },
  props: {
    members: { type: Array, required: true },
    groupId: { type: String, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'fullName', width: '90%', sortable: true },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ],
      groupMembersApi: null
    }
  },
  mounted() {
    this.groupMembersApi = groupMembersApiBuilder.build(this.groupId)
  },
  methods: {
    addMember() {
      this.$router.push({
        name: 'group-member-create',
        params: {
          groupId: this.groupId
        }
      })
    },
    async removeMember(member) {
      await this.groupMembersApi.delete(member.id)
      this.members.splice(this.members.indexOf(member), 1)
    },
    viewMember() {
      this.$emit('viewMember', this.model)
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
