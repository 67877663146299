<template>
  <div>
    <!-- <a :download="pdf.name" :href="dataUrl"><v-icon>mdi-download</v-icon></a> -->
    <iframe v-if="dataUrl" :src="dataUrl" height="500px" width="100%"></iframe>
  </div>
</template>

<script>
import { media } from '@/services/api'

export default {
  props: {
    value: { type: Object, default: null },
    noCache: { type: Boolean, default: false }
  },
  data() {
    return {
      dataUrl: null,
      pdf: null
    }
  },
  watch: {
    value(v) {
      this.loadDocument(v)
    }
  },
  mounted() {
    this.loadDocument(this.value)
  },
  methods: {
    async loadDocument(model) {
      try {
        this.fetching = true
        this.pdf = model

        const response = await media.files.getBlob(model, 'original', {
          noCache: this.noCache
        })

        if (response) {
          this.dataUrl = URL.createObjectURL(response)
        }
      } catch (err) {
        console.log('failed to load pdf', err)
        this.error = err.message
      } finally {
        this.fetching = false
      }
    }
  },
  destroyed() {
    if (this.dataUrl) {
      URL.revokeObjectURL(this.dataUrl)
    }
  }
}
</script>
