import GroupsIndex from '@/views/group/index'
import GroupCreate from '@/views/group/create'
import GroupDetail from '@/views/group/detail'
import GroupEdit from '@/views/group/edit'

import GroupMemberCreate from '@/views/group/members/create'

export default [
  {
    path: '/groups',
    name: 'groups-index',
    component: GroupsIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/groups/new',
    name: 'group-create',
    component: GroupCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/groups/:groupId',
    name: 'group-detail',
    component: GroupDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/groups/:groupId/edit',
    name: 'group-edit',
    component: GroupEdit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/groups/:groupId/members/new',
    name: 'group-member-create',
    component: GroupMemberCreate,
    meta: {
      authenticated: true
    }
  }
]
