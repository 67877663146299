<template>
  <v-card flat tile>
    <v-card-text>
      <video
        id="video-preview"
        :src="dataUrl"
        controls
        style="width: 100%; max-width: 450px"
      ></video>
    </v-card-text>
  </v-card>
</template>

<script>
import { media } from '@/services/api'

export default {
  props: {
    value: { type: Object, default: null },
    height: { type: String, default: 'auto' },
    noCache: { type: Boolean, default: false },
    resolution: { type: String, default: 'original' }
  },
  data() {
    return {
      dataUrl: null,
      video: null
    }
  },
  watch: {
    value(v) {
      this.loadVideo(v)
    }
  },
  mounted() {
    this.loadVideo(this.value)
  },
  methods: {
    async loadVideo(model) {
      try {
        this.fetching = true
        this.video = model
        const response = await media.files.getDataUrl(model, 'original', {
          noCache: this.noCache
        })
        if (response) {
          this.dataUrl = response
        }
      } catch (err) {
        console.log('failed to load video', err)
        this.error = err.message
      } finally {
        this.fetching = false
      }
    }
  },
  destroyed() {
    if (this.dataUrl) {
      URL.revokeObjectURL(this.dataUrl)
    }
  }
}
</script>

<style></style>
