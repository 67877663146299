<template>
  <div>
    <v-toolbar
      v-if="editor"
      fixed
      color="grey lighten-2"
      dense
      class="editor-toolbar"
    >
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        <v-icon>mdi-format-header-2</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        <v-icon>mdi-format-header-3</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <v-icon>mdi-format-bold</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <v-icon>mdi-format-italic</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().setTextAlign('left').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
      >
        <v-icon>mdi-format-align-left</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().setTextAlign('center').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
      >
        <v-icon>mdi-format-align-center</v-icon>
      </v-btn>
      <v-btn
        icon
        @click="editor.chain().focus().setTextAlign('right').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
      >
        <v-icon>mdi-format-align-right</v-icon>
      </v-btn>
      <v-btn icon @click="editor.chain().focus().undo().run()">
        <v-icon>mdi-undo</v-icon>
      </v-btn>
      <v-btn icon @click="editor.chain().focus().redo().run()">
        <v-icon>mdi-redo</v-icon>
      </v-btn>
      <v-btn
        small
        @click="editor.chain().focus().insertMedia().run()"
        :class="{ 'is-active': editor.isActive('iip-media') }"
        icon
      >
        <v-icon>mdi-folder-multiple-image</v-icon>
      </v-btn>
      <!-- 
        Procedures now contain rich text and right now editor content can not be embedded in editor content
        <v-btn
        v-if="!options.hideProcedures"
        small
        @click="editor.chain().focus().insertProcedure().run()"
        :class="{ 'is-active': editor.isActive('iip-procedure') }"
        icon
      >
        <v-icon>mdi-checkbox-multiple-outline</v-icon>
      </v-btn> -->
    </v-toolbar>
    <v-card class="editor-sheet">
      <v-card-text>
        <!-- <bubble-menu
      class="bubble-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      :should-show="shouldShowBubbleMenu"
      v-if="editor && editable"
    >
      <v-btn-toggle dense background-color="grey lighten-3">
        <v-btn
          small
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          type="button"
          icon
        >
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>
        <v-btn
          small
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
          type="button"
          icon
        >
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>
        <v-btn
          small
          @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          icon
        >
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>
        <v-btn
          small
          @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          icon
        >
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>
        <v-btn
          small
          @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          icon
        >
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
        <v-btn
          small
          @click="editor.chain().focus().unsetAllMarks().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          icon
        >
          <v-icon>mdi-format-clear</v-icon>
        </v-btn>
      </v-btn-toggle>
    </bubble-menu>
    -->

        <!--
    <floating-menu
      class="floating-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      dense
      background-color="grey lighten-3"
      v-if="editor && editable"
    >
      <v-btn
        small
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        icon
      >
        <v-icon>mdi-format-header-1</v-icon>
      </v-btn>
      <v-btn
        small
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        icon
      >
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
      <v-btn
        small
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        icon
      >
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn
        small
        @click="editor.chain().focus().insertMedia().run()"
        :class="{ 'is-active': editor.isActive('iip-media') }"
        icon
      >
        <v-icon>mdi-folder-multiple-image</v-icon>
      </v-btn>
    </floating-menu> -->

        <editor-content :editor="editor" class="d-flex" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  Editor,
  EditorContent
  // BubbleMenu,
  //  FloatingMenu
} from '@tiptap/vue-2'
import { isTextSelection } from '@tiptap/core'
import Config from './config'

const defaultContent = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      attrs: {
        textAlign: 'left'
      },
      content: []
    }
  ]
}

const defaultOptions = {hideProcedures: false}
export default {
  components: {
    EditorContent
    // BubbleMenu,
    // FloatingMenu
  },

  props: {
    value: {
      type: Object,
      default: () => {
        return defaultContent
      }
    },
    editable: { type: Boolean, default: true },
    options: { type: Object, default: () => {return {...defaultOptions}}}
  },

  data() {
    return {
      editor: null
    }
  },
  computed: {
    editorCss() {
      if (this.editable) {
        return ['editor-sheet', 'grey', 'lighten-4']
      } else {
        return ['editor-sheet']
      }
    }
  },
  watch: {
    value(value) {
      if (_.isEqual(this.editor.getJSON(), value)) {
        return
      }

      this.editor.commands.setContent(value, false)
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: Config.extensions,
      iipOptions: { ...this.options },
      content: _.isEmpty(this.value) ? Config.defaultDocument : this.value,
      onUpdate: () => {
        this.$emit('input', this.editor.getJSON())
      },
      editable: this.editable
    })
  },

  methods: {
    shouldShowBubbleMenu(props) {
      function getNestedProperty(obj, ...args) {
        return args.reduce((obj, level) => obj && obj[level], obj)
      }

      const ignore = ['iip-media']

      const name = getNestedProperty(
        props,
        'state',
        'selection',
        'node',
        'type',
        'name'
      )

      if (ignore.includes(name)) {
        return false
      }

      for (let i = 0; i < ignore.length; i++) {
        if (this.editor.isActive(ignore[i])) {
          return false
        }
      }

      /* fall back to default behavior... take from bubblemenu plugin source */
      const { state, from, to } = props
      const { doc, selection } = state
      const { empty } = selection

      // Sometime check for `empty` is not enough.
      // Doubleclick an empty paragraph returns a node size of 2.
      // So we check also for an empty text size.
      const isEmptyTextBlock =
        !doc.textBetween(from, to).length && isTextSelection(selection)

      if (empty || isEmptyTextBlock) {
        return false
      }

      return true
    }
  },

  beforeUnmount() {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.editor-sheet {
  min-height: 2rem;
  padding: 5px;
}
.editor-toolbar {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  z-index: 2;
}

.v-dialog__content .editor-toolbar{
  top:0;
}
</style>
