<script>
import { mapActions, mapGetters } from 'vuex'
import coverImageMix from './cover-image'

import appToolbar from '@/components/layout/app-toolbar'
import coverImageSelector from '@/components/media/cover-image-selector'
import coverImage from '@/components/media/cover-image'

import description from '@/components/form/description'
import archiveDialog from '@/components/dialogs/archive'
import restoreDialog from '@/components/dialogs/restore'
import deleteDialog from '@/components/dialogs/delete'
import versionChips from '@/components/version-chips'

export default {
  mixin: [coverImageMix],
  components: {
    appToolbar,
    coverImageSelector,
    coverImage,
    description,
    archiveDialog,
    deleteDialog,
    restoreDialog,
    versionChips
  },
  data() {
    return {
      archiveDialog: false,
      deleteDialog: false,
      restoreDialog: false,
      isPublished: false
    }
  },
  computed: {
    ...mapGetters('context', {
      moduleCrumbs: 'moduleCrumbs',
      module: 'module',
      component: 'component',
      procedure: 'procedure',
      topic: 'topic'
    }),
    crumbs() {
      return this.moduleCrumbs
    },
    moduleId() {
      return this.module.id
    },
    componentId() {
      return this.component.id
    },
    procedureId() {
      return this.procedure.id
    },
    topicId() {
      return this.topic.id
    },
    actionRouteParams() {
      return {}
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    selectedAction(action) {
      if (action.callback) {
        return action.callback()
      }

      if (action.routeName) {
        this.$router.push({
          name: action.routeName,
          params: this.actionRouteParams
        })
      }
    },
    showArchive() {
      this.archiveDialog = true
    },
    showRestore() {
      this.restoreDialog = true
    },
    showDelete() {
      this.deleteDialog = true
    },
    onCancel() {
      this.archiveDialog = false
      this.restoreDialog = false
      this.deleteDialog = false
    }
  }
}
</script>

<style></style>
