import Vue from 'vue'
import Vuex from 'vuex'
import context from './modules/context'
import messages from './modules/messages'
import user from './modules/user'
import theme from './modules/theme'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    title: 'Admin'
  },
  mutations: {
    title(state, payload) {
      state.title = payload.title
    }
  },
  actions: {
    setTitle({ commit }, payload) {
      commit('title', payload)
      Vue.nextTick(() => {
        if (document) {
          document.title = payload.title
        }
      })
    }
  },
  modules: {
    context,
    messages,
    user,
    theme
  }
})

export default store
