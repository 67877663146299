<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <v-form>
      <v-row style="padding-top: 30px">
        <v-col cols="5">
          <v-text-field
            @keydown="resetPage"
            v-model="searchUser"
            label="filter by user"
            dense
            solo
            class="search"
          >
            <template v-slot:prepend-inner>
              <v-icon title="common searches" small> mdi-filter </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-spacer />
        <v-col cols="5">
          <v-text-field
            v-model="searchModule"
            label="filter by module"
            dense
            solo
            class="search"
          >
            <template v-slot:prepend-inner>
              <v-icon title="common searches" small> mdi-filter </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="tableColumn">
        <div style="overflow-x: auto" class="table">
          <table id="accessTable">
            <thead>
              <tr>
                <th
                  align="left"
                  class="text-truncate"
                  @click="sortList('name')"
                >
                  User
                  <v-icon
                    small
                    v-if="currentSortCol === 'name' && currentSortDir === 'asc'"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon
                    small
                    v-if="
                      currentSortCol === 'name' && currentSortDir === 'desc'
                    "
                    >mdi-arrow-down</v-icon
                  >
                </th>
                <th v-for="header in filteredHeaders" :key="header.id">
                  {{ header.name }}
                  <!-- <v-icon
                    small
                    v-if="
                      header.id === currentSortCol && currentSortDir === 'asc'
                    "
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon
                    small
                    v-if="
                      header.id === currentSortCol && currentSortDir === 'desc'
                    "
                    >mdi-arrow-down</v-icon
                  > -->
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in paginatedRows" :key="row.id" @click="view(row)">
                <td>{{ row.name }}</td>
                <td v-for="header in filteredHeaders" :key="header.id">
                  <div class="d-flex justify-center">
                    <v-tooltip bottom color="#424242">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="row[header.id].permission === 'read'"
                          v-bind="attrs"
                          v-on="on"
                          color="#000000"
                          >mdi-eye-outline</v-icon
                        >
                        <v-icon
                          v-else-if="row[header.id].permission === 'write'"
                          v-bind="attrs"
                          v-on="on"
                          color="#FEBE10"
                          >mdi-pencil-outline</v-icon
                        >
                        <v-icon
                          v-else-if="row[header.id].permission === 'publish'"
                          v-bind="attrs"
                          v-on="on"
                          color="#000080"
                          >mdi-newspaper-variant-outline</v-icon
                        >
                      </template>
                      <span>
                        <span
                          v-if="row[header.id].permission"
                          style="text-transform: capitalize"
                          >Permission: {{ row[header.id].permission }}</span
                        >
                        <span
                          v-if="row[header.id].role"
                          style="text-transform: capitalize"
                        >
                          Role: {{ row[header.id].role }}
                        </span>
                      </span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tfoot">
          <v-row class="text-right">
            <v-icon small class="legend" color="#000000"
              >mdi-eye-outline</v-icon
            >
            <span>Read</span>
            <v-icon small class="legend" color="#FEBE10"
              >mdi-pencil-outline</v-icon
            >
            <span> Write</span>
            <v-icon small class="legend" color="#000080"
              >mdi-newspaper-variant-outline</v-icon
            >
            <span> Publish</span>
            <v-spacer />
            <button
              :disabled="pageNumber === 0"
              :style="pageNumber === 0 ? { opacity: 0.5 } : ''"
              @click="prevPage"
            >
              <v-icon>mdi-arrow-left-circle</v-icon>
            </button>
            <span style="font-weight: bold"
              >{{ pageNumber + 1 }} of {{ pageCount }}</span
            >
            <button
              :disabled="pageNumber + 1 === pageCount"
              :style="pageNumber + 1 === pageCount ? { opacity: 0.5 } : ''"
              @click="nextPage"
            >
              <v-icon>mdi-arrow-right-circle</v-icon>
            </button>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import appToolbar from '@/components/layout/app-toolbar'
import { moduleAccess as moduleAccessApi } from '@/services/api'

export default {
  components: {
    appToolbar
  },
  data() {
    return {
      crumbs: [
        { text: 'Reports', to: { name: 'reports-index' } },
        { text: 'Member Access Report', disabled: true }
      ],
      headers: [],
      rows: [],
      searchUser: '',
      searchModule: '',
      currentSortCol: '',
      currentSortDir: 'asc',
      pageNumber: 0,
      rowsPerPage: 100,
      hover: false,
      sortedbyASC: true
    }
  },
  mounted() {
    this.loadReportData()
  },
  methods: {
    async loadReportData() {
      const response = await moduleAccessApi.get()
      this.rows = response.data.rows
      this.headers = response.data.headers
    },
    resetPage() {
      this.pageNumber = 0
    },
    nextPage: function () {
      if (this.pageNumber * this.rowsPerPage < this.filteredRows.length)
        this.pageNumber++
    },
    prevPage: function () {
      if (this.pageNumber > 0) this.pageNumber--
    },
    view(item) {
      this.$router.push({
        name: 'user-access',
        params: {
          userId: item.userId,
          userModules: item,
          route: 'membersAccess'
        }
      })
    },
    sortList(col) {
      this.pageNumber = 0

      if (col === this.currentSortCol) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      } else {
        this.currentSortCol = col
        this.currentSortDir === 'asc'
      }

      this.rows.sort(this.sortBy(col, this.currentSortDir))
    },
    sortBy(property, order) {
      this.currentSortDir = order
      const module = `${property}.permission`
      property = property === 'name' ? 'name' : module
      return function (a, b) {
        const varA =
          typeof a[property] === 'string'
            ? a[property].toUpperCase()
            : a[property]
        const varB =
          typeof b[property] === 'string'
            ? b[property].toUpperCase()
            : b[property]
        let comparison = 0
        if (varA > varB) comparison = 1
        else if (varA < varB) comparison = -1
        return order === 'desc' ? comparison * -1 : comparison
      }
    }
  },
  watch: {
    filter() {
      this.pageNumber = 0
    }
  },
  computed: {
    filteredRows() {
      return this.rows.filter((r) => {
        if (r['name'].toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1)
          return r
      })
    },
    filteredHeaders() {
      return this.headers.filter((h) => {
        if (
          h['name'].toLowerCase().indexOf(this.searchModule.toLowerCase()) > -1
        )
          return h
      })
    },
    pageCount() {
      return Math.ceil(this.filteredRows.length / this.rowsPerPage)
    },
    paginatedRows() {
      const start = this.pageNumber * this.rowsPerPage
      const end = start + this.rowsPerPage
      return this.filteredRows.slice(start, end)
    }
  }
}
</script>

<style scoped>
#accessTable {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  padding: 15px;
}
#accessTable td,
#accessTable th {
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 10px;
  white-space: nowrap;
  cursor: pointer;
}
#accessTable tr:nth-child(even) {
  background-color: #f2f2f2;
}
#accessTable tr:hover {
  background-color: #ddd;
}
#accessTable th {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #ddd;
  border-top: 3px solid #ddd;
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
}
span {
  display: block;
}
div.col {
  padding: 0px;
}
button {
  padding: 0px 30px;
}
.tfoot {
  padding: 30px;
}
.table {
  padding: 0px 10px;
}
.search {
  padding: 0px 15px;
}
.legend {
  padding-left: 10px;
}
</style>
