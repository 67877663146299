<template>
  <v-card flat tile>
    <v-toolbar v-if="editable" dense elevation="2" color="grey lighten-4 mb-1">
      <v-toolbar-title>Audio</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text class="ma-auto mx-auto text-center pt-10">
      <audio
        id="audio-preview"
        controls
        style="width: 100%; max-width: 450px"
      ></audio>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    editable: { type: Boolean, default: false },
    value: { type: File, required: true }
  },
  data() {
    return {
      file: null
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.loadAudio(value)
      }
    }
  },
  mounted() {
    this.loadAudio(this.value)
  },
  methods: {
    loadAudio(file) {
      this.file = file
      const audio = document.getElementById('audio-preview')
      audio.src = URL.createObjectURL(file)
    }
  }
}
</script>

<style></style>
