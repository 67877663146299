//
// EU Theme
//
const euTheme = {
  name: 'iip-eu',
  title: 'IIP EU',
  logo: 'iip/logo.png',
  favicon: 'favicon.ico',
  location: {
    latitude: 50.4501,
    longitude: 30.5234,
    mgrs: '46Q JF 064 064'
  }
}

export default euTheme
