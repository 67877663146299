<template>
  <v-card flat tile>
    <v-card-title>{{ title }}</v-card-title>
    <v-list flat subheader three-line>
      <v-list-item-group v-model="innerValue" multiple active-class="">
        <v-list-item value="content-creator" :disabled="disabled">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Content Creator</v-list-item-title>
              <v-list-item-subtitle
                >Allow member to create new modules</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-list-item>

        <v-list-item value="content-publisher" :disabled="disabled">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Content Publisher</v-list-item-title>
              <v-list-item-subtitle
                >Allow member to published modules</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-list-item>

        <v-list-item value="orgadmin" :disabled="disabled">
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Organization Admin</v-list-item-title>
              <v-list-item-subtitle
                >Allow member to manage organization members, settings and
                content</v-list-item-subtitle
              >
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    title: { type: String, default: 'Roles' },
    value: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data() {
    return {
      innerValue: [],
      roles: []
    }
  },
  watch: {
    innerValue(value) {
      if (value) {
        this.$emit('input', value)
      }
    },
    value(value) {
      this.innerValue = value
    }
  },
  mounted() {
    this.innerValue = this.value
  }
}
</script>

<style></style>
