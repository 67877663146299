import memberIndex from '@/views/members/index'
import memberCreate from '@/views/members/create'
import memberDetail from '@/views/members/detail'
import memberEdit from '@/views/members/edit'

export default [
  {
    path: '/members',
    name: 'member-index',
    component: memberIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/members/create',
    name: 'member-create',
    component: memberCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/members/:memberId',
    name: 'member-detail',
    component: memberDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/members/:memberId/edit',
    name: 'member-edit',
    component: memberEdit,
    meta: {
      authenticated: true
    }
  }
]
