<template>
  <div class="mt-2">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :disable-pagination="true"
      :hide-default-footer="true"
      class="elevation-1"
      @click:row="view"
      disable-sort
    >
      <template v-slot:item.current="{ item }">
        <v-chip v-if="item.id == current" small color="primary"></v-chip>
      </template>

      <template v-slot:item.createdDateTime="{ item }">
        {{ item.createdDateTime | dateFormat }}
      </template>

      <template v-slot:item.actions="{}">
        <div class="text-right"></div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    mediaId: { type: String, required: true },
    items: { type: Array, default: () => [] },
    current: { types: String, default: null }
  },
  data() {
    return {
      headers: [
        { text: '', value: 'current', sortable: false, width: '5%' },
        { text: 'Version', value: 'version', width: '20%' },
        { text: 'Created Date Time', value: 'createdDateTime', width: '60%' },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ]
    }
  },
  methods: {
    view(item) {
      const params = { mediaId: this.mediaId, versionId: item.id }
      this.$router.push({ name: 'media-version-detail', params })
    }
  }
}
</script>
