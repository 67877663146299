<template>
  <div :class="editorCss">
    <editor-content :editor="editor" class="d-flex" />
  </div>
</template>

<script>
import _ from 'lodash'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Config from './config'

const defaultContent = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      attrs: {
        textAlign: 'left'
      },
      content: []
    }
  ]
}

export default {
  components: {
    EditorContent
  },

  props: {
    value: {
      type: Object,
      default: () => {
        return defaultContent
      }
    },
    options: { type: Object, default: () => {} }
  },

  data() {
    return {
      editor: null
    }
  },
  computed: {
    editorCss() {
      return ['editor-sheet']
    }
  },
  watch: {
    value(value) {
      this.editor.commands.setContent(value, false)
    }
  },

  mounted() {
    this.editor = new Editor({
      extensions: Config.extensions,
      iipOptions: { ...this.options },
      content: _.isEmpty(this.value) ? Config.defaultDocument : this.value,
      editable: false
    })
  },
  beforeUnmount() {
    this.editor.destroy()
  }
}
</script>

<style lang="scss">
.editor-sheet {
  min-height: 2rem;
  padding: 5px;
}
</style>
