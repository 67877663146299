<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <procedures-form
      v-model="model"
      :modules="modules"
      @cancel="onCancel"
      @save="onSave"
    ></procedures-form>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import { procedures as proceduresApiBuilder } from '@/services/api'
import proceduresForm from './form'

export default {
  components: {
    appToolbar,
    proceduresForm
  },
  data() {
    return {
      modules: null,
      isLoading: true,
      proceduresApi: null,
      model: {}
    }
  },
  computed: {
    ...mapGetters('context', ['topic', 'module', 'component', 'moduleCrumbs']),
    crumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Procedure',
        disabled: true
      })
      return items
    },
    componentId() {
      return this.component.id
    },
    moduleId() {
      return this.module.id
    }
  },
  async mounted() {
    this.proceduresApi = proceduresApiBuilder.build(
      this.moduleId,
      this.componentId
    )
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage', 'addError']),
    onCancel() {
      this.$router.push({
        name: 'component-detail',
        params: { componentId: this.componentId }
      })
    },
    async onSave(model) {
      try {
        const response = await this.proceduresApi.post(
          _.pick(model, ['name', 'description'])
        )
        model.id = response.data.id

        this.addMessage({
          message: `Procedure '${model.name}' has been created.`
        })
        this.$router.push({
          name: 'procedure-detail',
          params: { procedureId: response.data.id }
        })
      } catch (e) {
        this.addError(e)
      }
    }
  }
}
</script>

<style></style>
