<template>
  <node-view-wrapper class="media-component" color="grey lighten-4">
    <div v-if="editable" class="editor-content">
      <div
        v-if="!mediaItem"
        class="d-flex pa-2 align-content-center justify-center full"
      >
        <v-btn @click="showSelect = true">Select Media</v-btn>
      </div>

      <v-form
        v-if="mediaItem && entity"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-hover v-slot="{ hover }" open-delay="100">
              <v-card
                :elevation="hover ? 16 : 0"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                @click="showSelect = !showSelect"
              >
                <v-card-text>
                  <component
                    :is="`${entity.mediaItem.mediaType}-preview`"
                    :value="mediaItem"
                    :editable="false"
                    :height="height"
                  />
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-form>

      <media-selector
        v-model="showSelect"
        :options="iipOptions"
        @selected="onMediaSelected"
      />
    </div>

    <div v-if="!editable && entity" class="content">
      <component
        :is="`${entity.mediaItem.mediaType}-preview`"
        :value="mediaItem"
        :editable="false"
        :height="height"
      />
    </div>
  </node-view-wrapper>
</template>

<script>
import baseComponent from '@/components/tiptap/extensions/base-component'
import mediaImage from '@/components/media/media-image'
import mediaSelector from '@/components/media/selectors/media-reference-selector.vue'
import { media as mediaApi } from '@/services/api'

export default {
  mixins: [baseComponent],
  components: {
    mediaImage,
    mediaSelector
  },
  data() {
    return {
      showSelect: false,
      resolution: 'original',
      valid: true,
      mediaItem: null,
      entity: null
    }
  },
  computed: {
    pos() {
      return this.getPos()
    },
    caption: {
      get: function () {
        return this.node.attrs.caption
      },
      set: function (val) {
        this.updateAttributes({
          caption: val
        })
      }
    },
    height: {
      get: function () {
        if(this.$vuetify.breakpoint.smAndDown){
          return '225px'
        }
        return this.node.attrs.height || '325px'
      },
      set: function (val) {
        this.updateAttributes({
          height: val
        })
      }
    },
  },
  mounted() {
    if (this.node.attrs.mediaId) {
      this.mediaItem = {
        mediaId: this.node.attrs.mediaId,
        mediaReferenceId: this.node.attrs.mediaReferenceId
      }

      this.load()
    }
  },
  methods: {
    async load() {
      this.entity = await mediaApi.references.fetch(
        this.mediaItem.mediaId,
        this.mediaItem.mediaReferenceId
      )
    },
    async onMediaSelected(media) {
      this.updateAttributes({
        mediaReferenceId: media.id,
        mediaId: media.mediaId
      })

      this.mediaItem = {
        mediaId: media.mediaId,
        mediaReferenceId: media.id
      }

      await this.load()

      this.showSelect = false

      this.editor.commands.focus(this.getPos() + 1)
    }
  }
}
</script>

<style lang="scss">
.media-container {
  &:hover {
    padding: 5px;
    cursor: pointer;
  }
}
.media-component {
  text-align: center;
  border-radius: 0.2rem;
  margin: 1rem 0;
  position: relative;
}

.content {
  margin-top: 1.5rem;
  padding: 1rem;
}

.editor-content {
  margin-top: 1.5rem;
  padding: 1rem;
}
</style>
