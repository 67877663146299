import {
  modules,
  components as componentsApiBuilder,
  topics as topicsApiBuilder,
  procedures as proceduresApiBuilder
} from '@/services/api'

export default {
  namespaced: true,
  state: {
    module: null,
    component: null,
    topic: null,
    procedure: null
  },
  mutations: {
    setModule(state, payload) {
      state.module = payload.module
    },
    setComponent(state, payload) {
      state.component = payload.component
    },
    setTopic(state, payload) {
      state.topic = payload.topic
    },
    setProcedure(state, payload) {
      state.procedure = payload.procedure
    },
    clear(state) {
      state.module = null
      state.component = null
      state.topic = null
      state.procedure = null
    },
    clearComponent(state) {
      state.component = null
      state.topic = null
      state.procedure = null
    },
    clearTopic(state) {
      state.topic = null
    },
    setCoverImage(state, payload) {
      state[payload.entityType].coverImage = payload.model.coverImage
      state[payload.entityType].coverImageId = payload.model.coverImageId
    }
  },
  actions: {
    async load({ commit, dispatch }, payload) {
      const { moduleId } = payload.to.params || {}

      if (!moduleId) {
        commit('clear')
        return
      }

      await dispatch('loadModule', payload.to.params)
    },
    async loadModule({ commit, getters, dispatch }, payload) {
      if (!payload.moduleId) {
        commit('setModule', {})
      }

      if (getters.module.id != payload.moduleId) {
        const module = await modules.fetch(payload.moduleId)
        commit('setModule', { module })
      }

      if (getters.module.id) {
        await dispatch('loadComponent', payload)
      }
    },
    async loadComponent({ commit, getters, dispatch }, payload) {
      if (!payload.componentId) {
        commit('clearComponent', {})
      }

      if (getters.component.id != payload.componentId) {
        const api = componentsApiBuilder.build(getters.module.id)
        const component = await api.fetch(payload.componentId)
        commit('setComponent', { component })
      }

      if (getters.component.id) {
        await dispatch('loadTopic', payload)
        await dispatch('loadProcedure', payload)
      }
    },
    async loadTopic({ commit, getters }, payload) {
      if (!payload.topicId) {
        commit('clearTopic', {})
      }

      if (getters.topic.id != payload.topicId) {
        const api = topicsApiBuilder.build(
          getters.module.id,
          getters.component.id
        )
        const topic = await api.fetch(payload.topicId)
        commit('setTopic', { topic })
      }
    },
    async loadProcedure({ commit, getters }, payload) {
      if (!payload.procedureId) {
        commit('setProcedure', {})
      }

      if (getters.procedure.id != payload.procedureId) {
        const api = proceduresApiBuilder.build(
          getters.module.id,
          getters.component.id
        )
        const procedure = await api.fetch(payload.procedureId)
        commit('setProcedure', { procedure })
      }
    },
    async clearModule({ commit }) {
      commit('clear', {})
    },
    async clearComponent({ commit }) {
      commit('clearComponent', {})
    },
    async clearTopic({ commit }) {
      commit('clearTopic', {})
    },
    async updateCoverImage({ commit }, payload) {
      commit('setCoverImage', payload)
    },
    async updateComponent({ commit, getters }, payload) {
      if (getters.component.id === payload.componentId) {
        const componentsApi = componentsApiBuilder.build(payload.moduleId)
        const component = await componentsApi.fetch(payload.componentId)
        commit('setComponent', { component })
        const topicsApi = topicsApiBuilder.build(
          getters.module.id,
          getters.component.id
        )
        const topic = await topicsApi.fetch(payload.topicId)
        commit('setTopic', { topic })
      }
    }
  },
  getters: {
    module: (state) => {
      return state.module || {}
    },
    component: (state) => {
      return state.component || {}
    },
    procedure: (state) => {
      return state.procedure || {}
    },
    topic: (state) => {
      return state.topic || {}
    },
    moduleCrumbs: (state) => {
      const crumbs = [{ text: 'Modules', to: { name: 'module-index' } }]

      if (state.module) {
        crumbs.push({
          text: state.module.name,
          to: { name: 'module-detail', params: { moduleId: state.module.id } },
          disable: false
        })

        if (state.component) {
          crumbs.push({
            text: state.component.name,
            to: {
              name: 'component-detail',
              params: {
                moduleId: state.module.id,
                componentId: state.component.id
              }
            },
            disabled: false
          })

          if (state.topic) {
            crumbs.push({
              text: state.topic.name,
              to: {
                name: 'topic-detail',
                params: {
                  moduleId: state.module.id,
                  componentId: state.component.id,
                  topicId: state.topic.id
                }
              }
            })
          }

          if (state.procedure) {
            crumbs.push({
              text: state.procedure.name,
              to: {
                name: 'procedure-detail',
                params: {
                  moduleId: state.module.id,
                  componentId: state.component.id,
                  procedureId: state.procedure.id
                }
              }
            })
          }
        }
      }

      return crumbs
    }
  }
}
