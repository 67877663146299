<template>
  <div>
    <page-list
      title="Groups"
      :to="{ name: 'group-create' }"
      to-label="Add"
      @search="onSearch"
      @clear="onSearchClear"
      :headers="headers"
      :items="groups"
      :paging="paging"
      @page:changed="onPageChange"
      @row:view="view"
    >
      <template v-slot:item.relations="{ item }">
        {{
          'Modules: ' +
          item.moduleCount +
          ' / ' +
          'Members: ' +
          item.memberCount
        }}
      </template>

      <template v-slot:item.actions="{}">
        <div class="text-right"></div>
      </template>
    </page-list>
  </div>
</template>

<script>
import { groups as groupsApi } from '@/services/api'
import pageList from '@/components/layout/page-list.vue'

export default {
  components: { pageList },
  data() {
    return {
      groups: [],
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Relations', value: 'relations', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      footerProps: {
        showItemsPerPage: false
      },
      loaded: false,
      paging: {},
      search: ''
    }
  },
  mounted() {
    this.loadGroups()
  },
  methods: {
    buildFilter() {},
    async loadGroups() {
      const response = await groupsApi.get(null, {
        params: {
          term: this.search,
          page: this.paging.currentPage
        }
      })

      this.paging = response.data.meta.paging
      this.groups = response.data.results
      this.loaded = true
    },
    onPageChange(pagination) {
      if (this.loaded) {
        this.paging.currentPage = pagination.page
        this.loadGroups()
      }
    },
    onSearchClear() {
      this.onSearch(null)
    },
    onSearch(term) {
      this.search = term
      this.paging.currentPage = 1
      this.loadGroups()
    },
    view(item) {
      this.$router.push({
        name: 'group-detail',
        params: {
          groupId: item.id
        }
      })
    }
  }
}
</script>
