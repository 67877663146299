<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
      <v-btn
        v-if="model.id"
        plain
        v-policy:module-update="module"
        :to="{
          name: 'topic-edit',
          params: {
            moduleId: moduleId,
            componentId: componentId,
            topicId: model.id
          }
        }"
      >
        Edit
      </v-btn>

      <v-menu bottom left offset-y pa-3>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="action in actions"
              :key="action.key"
              @click="selectedAction(action)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="action.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-policy:module-publish="module"></v-divider>
            <v-list-item
              v-policy:module-publish="module"
              v-if="model.status === 'draft'"
              @click="onPublish"
            >
              <v-list-item-content>
                <v-list-item-title>Publish</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                (model.status === 'draft' && !model.isDeleted) ||
                (model.status === 'published' && !model.isDeleted)
              "
              v-policy:module-publish="module"
              @click="showArchive"
            >
              <v-list-item-content>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="module"
              @click="showRestore"
            >
              <v-list-item-content>
                <v-list-item-title>Restore</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="module"
              @click="showDelete"
            >
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <version-chips v-model="model"></version-chips>

    <v-row class="mt-0">
      <v-col :sm="6" :xs="12" class="text-center">
        <cover-image v-model="model.coverImage" />
      </v-col>
      <v-col>
        <h3>{{ model.name }}</h3>
        <p v-html="markdownToHtml"></p>
      </v-col>
    </v-row>

    <v-divider class="ma-2"></v-divider>

    <viewer v-model="model.content" :options="options" />

    <versions-modal
      v-model="showVersions"
      :items="model.versions"
      @view="onViewVersion"
    />

    <archiveDialog
      :name="model.name"
      :unit="unit"
      v-model="archiveDialog"
      @cancel="onCancel"
      @archive="onArchive"
    >
      <p slot="text">
        All currently published data associated with this {{ unit }} will become
        unvailable.
      </p>
    </archiveDialog>

    <restoreDialog
      :name="model.name"
      :unit="unit"
      v-model="restoreDialog"
      @cancel="onCancel"
      @restore="onRestore"
    ></restoreDialog>

    <deleteDialog
      :name="model.name"
      :unit="unit"
      v-model="deleteDialog"
      @cancel="onCancel"
      @onDelete="onDelete"
    >
      <p slot="text">
        All media items not shared with other {{ unit }}s will also be deleted.
      </p>
    </deleteDialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { topics as topicsApiBuilder } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import versionsModal from '@/components/publishing/versions-modal.vue'
import viewer from '@/components/tiptap/viewer'
import DomPurify from 'dompurify'
import { marked } from 'marked'

export default {
  mixins: [contentPageMixin],
  components: {
    versionsModal,
    viewer
  },
  data() {
    return {
      unit: 'topic',
      model: {},
      isPublished: false,
      isProcedure: false,
      topicsApi: null,
      actions: [
        {
          key: 'knowledge-check',
          title: 'Knowledge Check',
          routeName: 'topic-knowledgecheck'
        },
        {
          key: 'versions',
          title: 'Versions',
          callback: () => (this.showVersions = true)
        }
        // {
        //   key: 'topic-view',
        //   title: 'View',
        //   routeName: ''
        // }
      ],
      showVersions: false
    }
  },
  computed: {
    ...mapGetters('context', ['topic', 'module', 'component']),
    actionRouteParams() {
      return { topicId: this.topic.id }
    },
    archiveEnabled() {
      return this.actions.map((action) => {
        action.disabled =
          this.model.status === 3 && action.key === 'component-archive'
        return action
      })
    },
    options() {
      return {
        entityId: this.topic.id,
        entityType: 'topic',
        moduleId: this.module.id,
        componentId: this.component.id
      }
    },
    markdownToHtml() {
      return DomPurify.sanitize(marked.parse(this.topic.description || ''))
    }
  },
  async mounted() {
    this.topicsApi = topicsApiBuilder.build(this.module.id, this.component.id)

    if (this.topic) {
      this.model = _.cloneDeep(this.topic)
    }
  },
  watch: {
    topic(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    ...mapActions('context', ['updateComponent']),
    onPublish() {
      const params = {
        moduleId: this.moduleId,
        componentId: this.componentId,
        topicId: this.model.id
      }
      this.$router.push({ name: 'topic-publish', params })
    },
    async onArchive() {
      await this.topicsApi.archive(this.topic.id)
      this.model.status = 'archived'
      this.archiveDialog = false
      const params = {
        moduleId: this.moduleId,
        componentId: this.componentId,
        topicId: this.model.id
      }
      this.updateComponent(params)
      this.addMessage({
        message: `Topic '${this.model.name}' has been archived.`
      })
    },
    async onRestore() {
      await this.topicsApi.restore(this.topic.id)
      this.model.status = 'draft'
      this.restoreDialog = false
      const params = {
        moduleId: this.moduleId,
        componentId: this.componentId,
        topicId: this.model.id
      }
      this.updateComponent(params)
      this.addMessage({
        message: `Topic '${this.model.name}' has been restored.`
      })
    },
    async onDelete() {
      await this.topicsApi.delete(this.topic.id)
      this.model.isDeleted = true
      this.deleteDialog = false
      this.$router.push({ name: 'topic-index' })
      this.addMessage({
        message: `Topic '${this.model.name}' has been deleted.`
      })
    },
    onViewVersion() {
      this.showVersions = false
      //Todo - link to module version view
    }
  }
}
</script>

<style scoped>
.card {
  margin: 0px 15px;
}
.procedure {
  margin-left: 15px;
}
</style>
