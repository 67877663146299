<template>
  <span v-if="display">
    <slot>authorized section</slot>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import policies from '@/services/policies'

export default {
  props: {
    policy: { type: String },
    role: { type: String },
    resource: null
  },
  data() {
    return {
      display: false
    }
  },
  computed: {
    ...mapGetters('user', ['profile', 'isInRole', 'isSysAdmin', 'isOrgAdmin'])
  },
  watch: {
    async policy(val) {
      this.display = policies.authorize(val)
    },
    async role(val) {
      this.dispaly = this.isInRole(val)
    }
  },
  mounted() {
    this.authorize()
  },
  methods: {
    async authorize() {
      this.display = this.policy
        ? policies.authorize(this.policy, this.resource)
        : this.isInRole(this.role)
    }
  }
}
</script>
