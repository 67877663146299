<template>
  <div>
    <page-list
      title="Media Library"
      :to="{ name: 'media-create' }"
      to-label="Add"
      @search="onSearch"
      @clear="onSearchClear"
      :headers="headers"
      :items="items"
      :paging="paging"
      :server-items-length="paging.totalPages"
      @page:changed="onPageChange"
      @sortBy:changed="onSortByChanged"
      @sortDesc:changed="onSortDescChanged"
      @row:view="view"
    >
      <template v-slot:filters>
        <strong>Media Type:</strong>

        <v-checkbox
          v-model="advancedFilters.mediaType"
          value="image"
          label="Images"
        ></v-checkbox>
        <v-checkbox
          v-model="advancedFilters.mediaType"
          value="document"
          label="Documents"
        ></v-checkbox>
        <v-checkbox
          v-model="advancedFilters.mediaType"
          value="video"
          label="Videos"
        ></v-checkbox>
        <v-checkbox
          v-model="advancedFilters.mediaType"
          value="audio"
          label="Audio"
        ></v-checkbox>
      </template>

      <template v-slot:item.mediaType="{ item }">
        <span style="text-transform: capitalize">
          {{ item.mediaType }}
        </span>
      </template>

      <template v-slot:item.status="{ item }">
        <span style="text-transform: capitalize">
          {{ item.version + ' / ' + item.status }}
        </span>
      </template>

      <template v-slot:item.mediaStatus="{ item }">
        <span style="text-transform: capitalize">
          {{ item.mediaStatus }}
        </span>
      </template>

      <template v-slot:item.eventDateTime="{ item }">
        {{ item.publishedDateTime | dateFormat }}
      </template>

      <template v-slot:item.createdDateTime="{ item }">
        {{ item.createdDateTime | dateFormat }}
      </template>
    </page-list>
  </div>
</template>

<script>
import { media } from '@/services/api'
import pageList from '@/components/layout/page-list.vue'
export default {
  components: {
    pageList
  },
  data() {
    return {
      items: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'mediaType' },
        // { text: 'Version/Status', value: 'status' },
        // { text: 'State', value: 'mediaStatus', sortable: false },
        { text: 'Created', value: 'createdDateTime', sortable: true }
      ],
      footerProps: {
        showItemsPerPage: false
      },
      advancedFilters: {
        mediaType: []
      },
      loaded: false,
      paging: {
        totalPages: 0
      },
      sortBy: 'createdDateTime',
      isAscending: false,
      search: ''
    }
  },
  computed: {
    filterCount() {
      return this.advancedFilters.reduce(function (a, b) {
        return parseInt(a) + parseInt(b)
      }, 0)
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const response = await media.api.get(null, {
        params: {
          term: this.search,
          page: this.paging.currentPage,
          orderby: this.sortBy,
          isAscending: this.isAscending,
          mediaTypes: this.advancedFilters.mediaType.join(',')
        }
      })

      this.paging = response.data.meta.paging
      this.items = response.data.results
      this.loaded = true
    },
    onPageChange(pagination) {
      if (this.loaded) {
        this.paging.currentPage = pagination.page
        this.load()
      }
    },
    onSortByChanged(sortBy) {
      if (this.loaded && this.sortBy != sortBy) {
        this.sortBy = sortBy
        this.isAscending = true
        this.paging.currentPage = 1
        this.load()
      }
    },
    onSortDescChanged(sortDesc) {
      if (this.loaded && sortDesc != !this.isAscending) {
        this.isAscending = !sortDesc
        this.load()
      }
    },
    onSearchClear() {
      this.onSearch(null)
    },
    onSearch(term) {
      this.search = term
      this.paging.currentPage = 1
      this.load()
    },
    view(item) {
      this.$router.push({
        name: 'media-detail',
        params: { mediaId: item.id }
      })
    }
  }
}
</script>

<style scoped></style>
