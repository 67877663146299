<template>
  <div>
    <span>
      <span v-html="truncatedDescription" v-if="!readMore" class=""></span>
      <a
        class=""
        :style="linkStyle"
        v-if="!readMore && length > maxLength"
        @click="activateReadMore"
      >
        ...more
      </a>
      <span v-html="markdownToHtml" v-if="readMore" class="text-body-1"></span>
      <a class="" :style="linkStyle" v-if="readMore" @click="activateReadLess">
        ...less
      </a>
    </span>
  </div>
</template>

<script>
import DomPurify from 'dompurify'
import { marked } from 'marked'

export default {
  props: {
    description: { type: String, default: '' },
    maxLength: { type: Number, default: 400 }
  },
  data() {
    return {
      readMore: false,
      linkStyle: {
        color: 'blue'
      }
    }
  },
  computed: {
    length() {
      return this.description.length
    },
    markdownToHtml() {
      return DomPurify.sanitize(marked.parse(this.description || ''))
    },
    truncatedDescription() {
      return this.markdownToHtml.slice(0, this.maxLength)
    }
  },
  methods: {
    activateReadMore() {
      this.readMore = true
    },
    activateReadLess() {
      this.readMore = false
    }
  }
}
</script>
