<template>
  <div class="text-center">
    <h2>IIP logout complete</h2>
    <div class="ma-4">
      <v-btn link x-large @click="login" color="primary"> Login </v-btn>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'

export default {
  methods: {
    login() {
      AuthService.login()
    }
  }
}
</script>

<style></style>
