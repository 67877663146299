<template>
  <v-app>
    <v-app-bar app color="#D1D3D4" clipped-left flat>
      <v-app-bar-nav-icon @click.stop="goHome">
        <v-img
          alt="Logo"
          class="shrink mr-2 ml-4"
          contain
          :src="require(`@/assets/${theme.logo}`)"
          transition="scale-transition"
          width="35"
        />
      </v-app-bar-nav-icon>
      <app-header-title />
      <v-spacer></v-spacer>
      <login-info />
    </v-app-bar>

    <div v-if="isReady">
      <nav-drawer v-if="isAuthenticated" :layout="layout" />

      <v-main>
        <v-container fluid>
          <transition
            name="routerAnimation"
            enter-active-class="animated faster fadeIn"
          >
            <router-view></router-view>
          </transition>
        </v-container>
      </v-main>
    </div>

    <div v-if="!isReady && route.meta.allowAnonymous">
      <v-main>
        <v-container fluid>
          <transition
            name="routerAnimation"
            enter-active-class="animated faster fadeIn"
          >
            <router-view></router-view>
          </transition>
        </v-container>
      </v-main>
    </div>

    <div v-if="!isReady && isAuthenticated">
      <v-main>
        <v-container fluid>
          <div class="text-center">
            You do not have admin access to
            <strong>{{ organization.name }}</strong>
          </div>
        </v-container>
      </v-main>
    </div>

    <notifications></notifications>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AppHeaderTitle from '@/components/layout/app-header-title.vue'
import LoginInfo from '@/components/layout/login-info'
import NavDrawer from '@/components/layout/nav-drawer/index.vue'
import Notifications from '@/components/layout/app-notifications'

export default {
  name: 'App',

  components: {
    AppHeaderTitle,
    LoginInfo,
    NavDrawer,
    Notifications
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'isReady', 'organization']),
    ...mapGetters('theme', ['theme']),
    route() {
      return this.$route
    },
    isSystem() {
      return this.$route.path.startsWith('/system')
    },
    layout() {
      return this.isSystem ? 'system' : 'default'
    }
  },
  created() {
    this.setFavicon()
  },
  methods: {
    goHome() {
      if (this.$route.path != '/') {
        this.$router.push({ path: '/' })
      }
    },
    setFavicon() {
      const favicon = document.getElementById('favicon')
      favicon.href = this.theme.favicon
      console.log('favicon is: ', favicon.href)
    }
  }
}
</script>

<style scoped>
main div {
  height: 100%;
}

::v-deep .v-breadcrumbs__item {
  color: rgba(0, 0, 0, 0.87);
}
</style>
