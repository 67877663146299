<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <organizations-form :item="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import { organizations as organizationsApi } from '@/services/api'
import organizationsForm from './form'

export default {
  components: {
    appToolbar,
    organizationsForm
  },
  data() {
    return {
      crumbs: [
        { text: 'Organizations', to: { name: 'organization-index' } },
        { text: 'Create', disabled: true }
      ],
      model: {}
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      const response = await organizationsApi.post(model)

      this.addMessage({
        message: `Organization '${model.name}' has been created.`
      })

      this.$router.push({
        name: 'organization-detail',
        params: { organizationId: response.data.id }
      })
    }
  }
}
</script>

<style></style>
