<template>
  <div>
    <app-toolbar :crumbs="localCrumbs">
      <v-spacer></v-spacer>
      <knowledge-check-edit
        v-if="knowledgeCheck.id"
        v-model="knowledgeCheck"
        :api="topicsApi"
        @save="onSave"
      />
    </app-toolbar>

    <knowledge-check-detail
      v-if="model.id"
      v-model="knowledgeCheck"
      :entityType="'topic'"
      :api="topicsApi"
      @save="onSave"
    />

    <div class="mt-2">
      <questions-table
        :topicId="topicId"
        :mediaOptions="mediaOptions"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { topics as topicsApiBuilder } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import KnowledgeCheckDetail from '@/components/knowledgeCheck/detail.vue'
import KnowledgeCheckEdit from '@/components/knowledgeCheck/edit.vue'
import QuestionsTable from '@/components/knowledgeCheck/questions-table.vue'

export default {
  mixins: [contentPageMixin],
  components: {
    KnowledgeCheckDetail,
    KnowledgeCheckEdit,
    QuestionsTable
  },
  data() {
    return {
      knowledgeCheck: {},
      model: {},
      topicsApi: null
    }
  },
  computed: {
    localCrumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Knowledge Check',
        disabled: true
      })
      return items
    },
    mediaOptions() {
      return {
        showUpload: true,
        title: 'Media Selector',
        moduleId: this.moduleId,
        componentId: this.componentId,
        topicId: this.topicId,
        procedureId: this.procecdureId,
        entityId: this.topicId,
        entityType: 'topic'
      }
    }
  },
  async mounted() {
    this.topicsApi = topicsApiBuilder.build(this.module.id, this.componentId)
    this.model = this.topic
    this.knowledgeCheck = await this.topicsApi.knowledgeCheck.fetch(
      this.topic.id
    )
  },
  watch: {
    topic(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    onSave(model) {
      this.knowledgeCheck = { ...model }
    }
  }
}
</script>
