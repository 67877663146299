<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <group-form :item="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import { groups as groupsApi } from '@/services/api'
import groupForm from './form'

export default {
  components: {
    appToolbar,
    groupForm
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    ...mapGetters('messages', ['errors']),
    id() {
      return this.$route.params.groupId
    },
    crumbs() {
      const items = [{ text: 'Groups', to: { name: 'groups-index' } }]

      if (this.model) {
        items.push({ text: this.model.name, disabled: true })
        items.push({ text: 'Edit', disabled: true })
      }

      return items
    }
  },
  async mounted() {
    this.model = await groupsApi.fetch(this.id)
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      await groupsApi.put(model.id, model)
      this.$router.push({
        name: 'group-detail',
        params: { groupId: model.id }
      })
    }
  }
}
</script>

<style></style>
