<template>
  <div class="text-center">
    <v-dialog v-model="show" class="modal" max-width="550" persistent>
      <v-card>
        <v-card-title class="headline"> Change Organization </v-card-title>
        <v-card-subtitle
          >Current organization:
          <strong> {{ organization.name }} </strong></v-card-subtitle
        >

        <v-card-text>
          <h4>Available Organizations</h4>
          <v-list dense>
            <v-list-item-group v-model="selected" color="primary">
              <v-list-item v-for="o in available" :value="o" :key="o.id">
                <v-list-item-content>
                  <v-list-item-title v-text="o.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" plain @click="cancel"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="selected"
            color="success"
            tile
            dark
            large
            @click="change"
          >
            Change to "{{ selected.name }}"</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { organizations as OrganizationApi } from '@/services/api/'

export default {
  props: {
    name: { type: String },
    value: Boolean
  },
  data() {
    return {
      tab: null,
      available: [],
      selected: null
    }
  },
  computed: {
    ...mapGetters('user', ['profile', 'organization', 'isSysAdmin']),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.loadAvailableOrganizations()
      }
    }
  },
  methods: {
    ...mapActions('user', ['setOrganization']),
    cancel() {
      this.show = false
      this.$emit('cancel')
    },
    async change() {
      const o = {
        tenantId: this.selected.tenantId,
        name: this.selected.name,
        organizationId: this.selected.id,
        roles: []
      }

      const selected = !this.isSysAdmin ? this.selected : o

      await this.setOrganization({ organization: selected })
      this.$router.push('/').catch(() => {})
      this.show = false
    },
    async loadAvailableOrganizations() {
      if (this.isSysAdmin) {
        const response = await OrganizationApi.get()
        this.available = response.data.results
      } else {
        this.available = this.profile.membership
      }
    }
  }
}
</script>
