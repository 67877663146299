<template>
  <v-card flat tile>
    <v-toolbar v-if="editable" dense elevation="2" color="grey lighten-4 mb-1">
      <v-toolbar-title>Document</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text class="text-center">
      <iframe v-if="dataUrl" :src="dataUrl" height="500px" width="90%"></iframe>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    editable: { type: Boolean, default: false },
    value: { type: File, required: true }
  },
  data() {
    return {
      dataUrl: null,
      file: null
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.loadDocument(value)
      }
    }
  },
  mounted() {
    this.loadDocument(this.value)
  },
  methods: {
    loadDocument(file) {
      this.file = file
      this.dataUrl = URL.createObjectURL(file)
    }
  },
  destroyed() {
    if (this.dataUrl) {
      URL.revokeObjectURL(this.dataUrl)
    }
  }
}
</script>
