<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
      <v-btn plain :to="{ name: 'group-edit', params: { groupId: this.id } }">
        Edit
      </v-btn>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item @click="deleteGroup">
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <v-card flat tile>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row no-gutters class="mt-2">
            <v-col>
              <v-col cols="12">
                <v-text-field
                  readonly
                  label="Group Name"
                  required
                  v-model="model.name"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  readonly
                  name="input-7-1"
                  label="Description"
                  auto-grow
                  v-model="model.description"
                ></v-textarea>
              </v-col>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-divider class="ma-2"></v-divider>

    <modulesTable
      :modules="modules"
      @addModule="addModule"
      @viewModule="viewModule"
    ></modulesTable>

    <membersTable :members="members" :groupId="id" />

    <deleteDialog
      :name="model.name"
      :unit="unit"
      v-model="deleteDialog"
      @cancel="onCancelDelete"
      @onDelete="onDelete"
    ></deleteDialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { groups as groupsApi } from '@/services/api'
import appToolbar from '@/components/layout/app-toolbar'
import modulesTable from './components/modules-table'
import membersTable from './members/members-table'
import deleteDialog from '@/components/dialogs/delete'

export default {
  components: {
    appToolbar,
    modulesTable,
    membersTable,
    deleteDialog
  },
  data() {
    return {
      unit: 'group',
      model: {},
      archiveDialog: false,
      restoreDialog: false,
      deleteDialog: false,
      members: [],
      modules: [],
      valid: true,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 250) || 'Name must be less than 250 characters'
      ]
    }
  },
  computed: {
    id() {
      return this.$route.params.groupId
    },
    crumbs() {
      const items = [{ text: 'Groups', to: { name: 'groups-index' } }]

      if (this.model) {
        items.push({ text: this.model.name, disabled: true })
      }

      return items
    }
  },
  watch: {
    id(newVal) {
      this.loadGroup(newVal)
    }
  },
  mounted() {
    if (this.id) {
      this.loadGroup(this.id)
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    async loadGroup(id) {
      this.model = await groupsApi.fetch(id)
      // this.members = this.model.members.sort((a,b) => (a.fullName.toLowerCase() > b.fullName.toLowerCase()) ? 1 : -1)
      // this.modules = this.model.modules.sort((a,b) => (a.moduleName.toLowerCase() > b.moduleName.toLowerCase()) ? 1 : -1)
      this.members = this.model.members || []
      this.modules = this.model.modules || []
    },
    deleteGroup() {
      this.deleteDialog = true
    },
    onCancelDelete() {
      this.deleteDialog = false
    },
    onCancel() {
      this.$router.push({ name: 'groups-index' })
    },
    async onSave(model) {
      await groupsApi.put(model.id, model)
      this.addMessage({
        message: `Group '${this.model.name}' has been updated.`
      })
    },
    async onDelete() {
      await groupsApi.delete(this.id)
      this.model.isDeleted = true
      this.deleteDialog = false
      this.$router.push({ name: 'groups-index' })
      this.addMessage({
        message: `Group '${this.model.name}' has been deleted.`
      })
    },
    addModule() {
      this.$router.push({
        name: 'group-module-create',
        params: { groupId: this.model.id }
      })
    },
    viewModule(item) {
      this.$router.push({
        name: 'group-module-edit',
        params: {
          groupModuleId: item.id,
          groupId: this.model.id
        }
      })
    }
  }
}
</script>

<style></style>
