<template>
  <v-sheet :style="containerStyle" class="d-flex justify-center align-center ma-0 pa-0">
    <v-skeleton-loader
      v-if="loading"
      class="mx-auto"
      width="100%"
      type="image"
    ></v-skeleton-loader>
    <div v-if="error"></div>
    <div v-if="dataUrl" :style="containerStyle">
      <div v-if="background" class="media-image" :style="imageStyle"></div>
      <v-img v-else class="media-image" :src="dataUrl" :max-width="width || maxWidth" :max-height="height || maxHeight" contain/>
    </div>
  </v-sheet>
</template>

<script>
import _ from 'lodash'
import { media } from '@/services/api'

export default {
  props: {
    background: { type: Boolean, default: false },
    resolution: { type: String, default: 'thumbnail' },
    value: { type: Object, default: null },
    filePath: { type: String, default: null },
    height: { type: String, default: 'null' },
    maxHeight: { type: String, default: '550px' },
    maxWidth: { type: String, default: '100%' },
    noCache: { type: Boolean, default: false },
    width: { type: String, default: 'auto' }
  },
  data() {
    return {
      innerValue: '',
      fetching: true,
      dataUrl: null,
      error: null
    }
  },
  computed: {
    loading() {
      if (!this.innerValue.mediaId) {
        return false
      }

      if (this.error) {
        return false
      }

      return this.fetching
    },
    containerStyle() {
      return {
        height: this.height,
        width: this.width,
        'max-height': this.maxHeight,
        'max-width': this.maxWidth
      }
    },
    imageStyle() {
      return `background-image: url('${this.dataUrl}');height:${this.height};width:${this.width};min-height:250px`
    }
  },
  watch: {
    innerValue(value) {
      if(_.isEmpty(value)) {
        this.dataUrl = null
        return
      }
      
      if (!value.dataUrl) {
        this.loadImage(value)
        this.$emit('input', value)
      }
    },
    filePath(value) {
      this.loadFilePath(value)
    },
    value(value) {
      if (value != this.innerValue) {
        this.innerValue = { ...value }
      }
    }
  },
  mounted() {
    if (this.filePath) {
      this.loadFilePath(this.filePath)
      return
    }
    if (this.value) {
      this.innerValue = { ...this.value }
    }
  },
  methods: {
    placeholderSrc() {
      return media.placeholder.IMAGE_PLACEHOLDER
    },
    async loadFilePath(filePath) {
      try {
        this.fetching = true

        const response = await media.files.getFileAsDataUrl(filePath, {
          noCache: this.noCache
        })
        if (response) {
          this.dataUrl = response
        }
      } catch (err) {
        console.log('failed to load image from file path', err)
        this.error = err.message
      } finally {
        this.fetching = false
      }
    },
    async loadImage(image) {
      try {
        this.fetching = true
        if (image && (image.mediaId ?? image.id)) {
          const response = await media.files.getDataUrl(
            image,
            this.resolution,
            {
              noCache: this.noCache
            }
          )
          if (response) {
            this.dataUrl = response
          }
        }
      } catch (err) {
        console.log('failed to load image', err)
        this.error = err.message
      } finally {
        this.fetching = false
      }
    }
  },
  create() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>

<style scoped>
.media-image {
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
</style>
