<template>
  <v-dialog v-model="show" min-width="450px">
    <v-app-bar flat elevation="0">
      <v-toolbar-title>{{ options.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <media-upload
        v-if="options.showUpload && !uploading"
        :accept="accept"
        @onUploaded="onFileUploaded"
        class="mr-3"
      />
      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-card flat tile>
      <v-toolbar elevation="0" rounded="false">
        <v-select
          :items="modules"
          v-model="module"
          label="Module"
          dense
          item-text="name"
          item-value="id"
          return-object
          @change="onModuleChange"
        ></v-select>

        <v-select
          :items="components"
          v-model="component"
          label="Component"
          dense
          :disabled="!filters.moduleId"
          item-text="name"
          item-value="id"
          return-object
          @change="onComponentChange"
        ></v-select>

        <v-select
          :items="topics"
          v-model="topic"
          label="Topic"
          dense
          :disabled="!filters.componentId"
          item-text="name"
          item-value="id"
          return-object
          @change="onTopicChange"
        ></v-select>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-for="m in media"
              :key="m.id"
              class="image"
              xs="6"
              sm="6"
              md="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="selectItem(m)"
                >
                  <v-container fluid>
                    <component
                      :is="`${m.mediaType}-preview`"
                      :value="m"
                      :editable="false"
                    />
                  </v-container>
                  <v-card-actions class="text-truncate">
                    {{ m.name }}
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="showUpload" xs="6" sm="6" md="3">
              <v-container>
                <v-progress-circular v-if="uploading"></v-progress-circular>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" plain @click="cancel"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import mediaUpload from '@/components/media/upload/modal.vue'
import {
  acceptMediaTypes,
  mediaTypePropertyValidator,
  mediaTypes
} from '../utils'
import { media as mediaApi } from '@/services/api'
import { lookups } from '@/services/api'

// Select any item from the media library (filtered by user permissions)

const defaultOptions = {
  showUpload: true,
  title: 'Media Selector',
  moduleId: null,
  componentId: null,
  topicId: null,
  procedureId: null
}

export default {
  components: {
    mediaUpload
  },
  props: {
    value: { type: Boolean, default: false },
    mediaType: {
      type: String,
      validator: (prop) => mediaTypePropertyValidator(prop)
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      module: null,
      component: null,
      topic: null,
      modules: [],
      components: [],
      topics: [],
      media: [],
      loaded: false,
      lookups: [],
      paging: {
        currentPage: 1
      },
      showUpload: false,
      show: false,
      selectorOptions: defaultOptions,
      uploading: false,
      filters: {
        moduleId: null,
        componentId: null,
        topicId: null,
        procedureId: null,
        mediaType: null,
        term: null
      }
    }
  },
  computed: {
    accept() {
      return acceptMediaTypes(this.mediaType)
    },
    mediaTypes() {
      return this.mediaType ? [this.mediaType] : mediaTypes
    },
    selected() {
      return this.options.selected ?? '-1'
    }
  },
  watch: {
    show(val) {
      this.$emit('input', val)
    },
    async value(val) {
      this.show = val
      if (val) {
        await this.loadLookups()
        this.load()
      }
    }
  },
  methods: {
    cancel() {
      this.show = false
    },
    async loadLookups() {
      _.defaults(this.options, defaultOptions)

      this.filters = {
        ...this.filters,
        moduleId: this.options.moduleId,
        componentId: this.options.componentId,
        topicId: this.options.topicId,
        procedureId: this.options.procedureId
      }

      this.lookups = await lookups.modules()
      this.modules = [{ name: 'All' }, ...this.lookups]

      if (this.filters.moduleId) {
        this.module = _.find(this.lookups, { id: this.filters.moduleId })
        this.components = this.module.components

        if (this.filters.componentId) {
          this.component = _.find(this.module.components, {
            id: this.filters.componentId
          })
          this.topics = this.component.topics
        }
      }
    },
    async load() {
      const response = await mediaApi.api.search({
        params: {
          page: this.paging.currentPage,
          mediaType: this.mediaType,
          ...this.filters
        }
      })

      this.paging = response.data.meta.paging
      this.media = response.data.results
      this.loaded = true
    },
    selectItem(item) {
      this.$emit('selected', item)
    },
    async onFileUploaded(upload) {
      this.uploading = true

      console.log('media-selector onFileUploaded', upload)

      this.file = upload.file
      this.fileType = upload.fileType
      const name = this.file.name.split('.').shift()

      const model = {
        name,
        description: name,
        entityId: this.options.entityId,
        entityType: this.options.entityType,
        mediaType: this.mediaType
      }

      const response = await mediaApi.api.postFile(this.file, model)
      const newItem = await mediaApi.references.fetch(
        response.id,
        response.referenceId
      )
      this.media.push(newItem.mediaItem)

      this.uploading = false
    },
    onModuleChange() {
      this.filters.componentId = null
      this.filters.moduleId = this.module?.id
      this.components = [{ name: 'All' }, ...(this.module?.components ?? [])]
      this.load()
    },
    onComponentChange() {
      this.filters.topicId = null
      this.filters.componentId = this.component?.id
      this.topics = [{ name: 'All' }, ...(this.component?.topics ?? [])]
      this.load()
    },
    onTopicChange() {
      this.filters.topicId = this.topic?.id
      this.load()
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  .active {
    border: 2px solid #43a047;
  }
}
</style>
