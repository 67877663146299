<template>
  <div>
    <app-toolbar :crumbs="crumbs" class="mb-2">
      <v-spacer></v-spacer>
      <v-btn
        v-if="!model.isDeleted"
        plain
        :to="{
          name: 'organization-edit',
          params: { organizationId: model.id }
        }"
      >
        Edit
      </v-btn>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-if="model.isDeleted"
              @click="restoreOrganization"
              @cancel="onCancel"
            >
              <v-list-item-content>
                <v-list-item-title>Restore</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="!model.isDeleted"
              @click="deleteOrganization"
              @cancel="onCancel"
            >
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <v-alert class="mt-2" v-if="model.isDeleted" color="red" type="error">
      This organization has been deleted
    </v-alert>

    <v-card flat>
      <v-card-title>{{ model.name }}</v-card-title>
      <v-card-text>
        <h4>Description</h4>
        <p>{{ model.description }}</p>
        <h4>Code</h4>
        <p>{{ model.code }}</p>
      </v-card-text>
    </v-card>

    <restoreDialog
      :name="model.name"
      :unit="unit"
      v-model="restoreDialog"
      @cancel="onCancel"
      @restore="onRestore"
    ></restoreDialog>

    <delete-dialog
      :name="model.name"
      :unit="unit"
      v-model="deleteDialog"
      @cancel="onCancel"
      @onDelete="onDelete"
    ></delete-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { organizations as organizationsApi } from '@/services/api'
import deleteDialog from '@/components/dialogs/delete'
import restoreDialog from '@/components/dialogs/restore'
import appToolbar from '@/components/layout/app-toolbar'

export default {
  components: {
    appToolbar,
    deleteDialog,
    restoreDialog
  },
  data() {
    return {
      unit: 'organization',
      model: {},
      deleteDialog: false,
      restoreDialog: false
    }
  },
  computed: {
    id() {
      return this.$route.params.organizationId
    },
    crumbs() {
      const items = [
        { text: 'Organizations', to: { name: 'organization-index' } }
      ]

      if (this.model) {
        items.push({ text: this.model.name, disabled: true })
      }

      return items
    }
  },
  watch: {
    id(newVal) {
      this.loadOrganization(newVal)
    }
  },
  mounted() {
    if (this.id) {
      this.loadOrganization(this.id)
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    async loadOrganization(id) {
      this.model = await organizationsApi.fetch(id)
    },
    deleteOrganization() {
      this.deleteDialog = true
    },
    restoreOrganization() {
      this.restoreDialog = true
    },
    onCancel() {
      this.deleteDialog = false
      this.restoreDialog = false
    },
    async onRestore() {
      await organizationsApi.restore(this.id)
      this.loadOrganization(this.id)
      this.restoreDialog = false
      this.addMessage({
        message: `Organization '${this.model.name}' has been restored.`
      })
    },
    async onDelete() {
      await organizationsApi.delete(this.id)
      this.model.isDeleted = true
      this.deleteDialog = false
      this.$router.push({ name: 'organization-index' })
      this.addMessage({
        message: `Organization '${this.model.name}' has been deleted.`
      })
    }
  }
}
</script>
