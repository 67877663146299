<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <module-form
      :item="model"
      @cancel="onCancel"
      @save="onSave"
      :mediaSelectorOptions="mediaSelectorOptions"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import coverImage from '@/components/mixins/cover-image'
import { modules as modulesApi } from '@/services/api'
import { media as mediaApi } from '@/services/api'
import moduleForm from './form'

export default {
  mixins: [coverImage],
  components: {
    appToolbar,
    moduleForm
  },
  data() {
    return {
      crumbs: [
        { text: 'Module', to: { name: 'module-index' } },
        { text: 'Edit', disabled: true }
      ],
      model: {}
    }
  },
  computed: {
    ...mapGetters('messages', ['errors']),
    id() {
      return this.$route.params.moduleId
    },
    coverImage() {
      if (!this.model?.coverImageId) {
        return null
      }

      return `${this.model.mediaType}-preview`
    },
    mediaSelectorOptions() {
      return {
        entityId: this.id,
        entityType: 'module',
        moduleId: this.id,
        selected: this.model.coverImageId,
        coverApi: modulesApi
      }
    }
  },
  async mounted() {
    this.model = await modulesApi.fetch(this.id)
  },
  methods: {
    ...mapActions('context', ['clearModule']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model, file) {
      await modulesApi.put(
        model.id,
        _.pick(model, ['id', 'name', 'description'])
      )

      if (model.coverImageId) {
        await this.saveCoverImage(model, 'module')

        if (this.model.coverImageId != model.coverImageId) {
          await modulesApi.cover(model.id, model.coverImageId)
        }
      } else if (file) {
        const media = {
          name: model.name,
          description: model.description,
          entityId: model.id,
          entityType: 'module'
        }

        const mediaResponse = await mediaApi.api.postFile(file, media)
        model.coverImage = mediaResponse.id
        model.coverImageId = mediaResponse.referenceId

        await modulesApi.cover(model.id, model.coverImageId)
      }

      await this.clearModule()

      this.$router.push({
        name: 'module-detail',
        params: { moduleId: model.id }
      })
    }
  }
}
</script>

<style></style>
