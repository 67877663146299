<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <components-form
      v-model="model"
      :item="model"
      :modules="modules"
      @cancel="onCancel"
      @save="onSave"
      :mediaSelectorOptions="mediaSelectorOptions"
    ></components-form>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import { components as componentsApiBuilder } from '@/services/api'
import { media as mediaApi } from '@/services/api'
import componentsForm from './form'

export default {
  components: {
    appToolbar,
    componentsForm
  },
  data() {
    return {
      modules: null,
      isLoading: true,
      componentsApi: null,
      model: {}
    }
  },
  computed: {
    ...mapGetters('context', ['topic', 'module', 'component', 'moduleCrumbs']),
    crumbs() {
      const items = [...this.moduleCrumbs]
      items.push(
        {
          text: 'Component',
          disabled: true
        },
        {
          text: 'Create',
          disabled: true
        }
      )
      return items
    },
    moduleId() {
      return this.module.id
    },
    mediaSelectorOptions() {
      return {
        entityId: this.component.id,
        entityType: 'component',
        moduleId: this.module.id,
        selected: this.component.coverImageId,
        coverApi: this.componentApi
      }
    }
  },
  async mounted() {
    this.componentsApi = componentsApiBuilder.build(this.moduleId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage', 'addError']),
    onCancel() {
      this.$router.push({
        name: 'module-detail',
        params: { moduleId: this.moduleId }
      })
    },
    async onSave(model, file) {
      try {
        const response = await this.componentsApi.post(
          _.pick(model, ['name', 'description'])
        )
        model = response.data

        if (file) {
          const media = {
            name: model.name,
            description: model.description,
            entityId: model.id,
            entityType: 'component'
          }

          const mediaResponse = await mediaApi.api.postFile(file, media)
          model.coverImage = mediaResponse.id
          model.coverImageId = mediaResponse.referenceId

          await this.componentsApi.cover(model.id, model.coverImageId)
        }

        this.addMessage({
          message: `Component '${model.name}' has been created.`
        })

        this.$router.push({
          name: 'component-detail',
          params: { componentId: model.id }
        })
      } catch (e) {
        this.addError(e)
      }
    }
  }
}
</script>

<style></style>
