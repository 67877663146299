<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          large
          @click="changeCover = !changeCover"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </template>
      <span>Edit Cover Image</span>
    </v-tooltip>
    <media-selector
      v-model="changeCover"
      :options="mediaSelectorOptions"
      mediaType="image"
      @selected="onImageSelected"
    />
  </div>
</template>

<script>
import _ from 'lodash'
//import { mapActions } from 'vuex'
import mediaSelector from '@/components/media/selectors/media-reference-selector.vue'

export default {
  components: {
    mediaSelector
  },
  props: {
    value: { type: Object, required: true },
    mediaSelectorOptions: { type: Object, required: true }
  },
  data() {
    return {
      changeCover: false,
      model: {}
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.value)
  },
  methods: {
    async onImageSelected(image) {
      if (image.id != this.model.coverImageId) {
        this.model.coverImage = image
        this.model.coverImageId = image.id
      }

      this.model = _.cloneDeep(this.model)
      this.changeCover = false
      this.$emit('imageUpdate', this.model)
    }
  }
}
</script>
