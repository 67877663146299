<script>
import {
  STATUS_FAILED,
  STATUS_SUCCESS,
  STATUS_SAVING,
  STATUS_INITIAL
} from './constants'
export default {
  data() {
    return {
      dragging: false,
      files: null,
      fileCount: 0,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null
    }
  },
  computed: {
    color() {
      return this.dragging ? 'green lighten-4' : 'grey lighten-3'
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    filesChange(fieldName, fileList) {
      this.currentStatus = STATUS_SAVING

      const formData = new FormData()

      if (!fileList.length) return

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name)
      })

      // save it
      //this.$emit('uploaded', fileList)
      this.files = fileList
      this.currentStatus = STATUS_SUCCESS
    }
  },
  mounted() {
    this.reset()
  }
}
</script>
