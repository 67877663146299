<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <topic-form
      v-model="model"
      :value="model"
      @cancel="onCancel"
      @save="onSave"
      :mediaSelectorOptions="mediaSelectorOptions"
    ></topic-form>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import contentPageMixin from '@/components/mixins/content-page'
import { topics as topicsApiBuilder } from '@/services/api'
import { media as mediaApi } from '@/services/api'
import topicForm from './form'

export default {
  mixins: [contentPageMixin],
  components: {
    topicForm
  },
  data() {
    return {
      isLoading: true,
      topicsApi: null,
      model: {}
    }
  },
  computed: {
    crumbs() {
      const items = [...this.moduleCrumbs]
      items.push({
        text: 'Topic',
        disabled: true
      })
      return items
    },
    mediaSelectorOptions() {
      return {
        entityId: this.topic.id,
        entityType: 'topic',
        moduleId: this.module.id,
        componentId: this.component.id,
        selected: this.topic.coverImageId,
        coverApi: this.topicsApi
      }
    }
  },
  async mounted() {
    this.topicsApi = topicsApiBuilder.build(this.moduleId, this.componentId)

    this.isLoading = false
  },
  methods: {
    ...mapActions('context', ['clearComponent']),
    onCancel() {
      this.$router.push({
        name: 'component-detail',
        params: { moduleId: this.moduleId, componentId: this.componentId }
      })
    },
    async onSave(model, file) {
      try {
        const response = await this.topicsApi.post(
          _.pick(model, ['name', 'description'])
        )
        model = response.data

        if (file) {
          const media = {
            name: model.name,
            description: model.description,
            entityId: model.id,
            entityType: 'topic'
          }

          const mediaResponse = await mediaApi.api.postFile(file, media)
          model.coverImage = mediaResponse.id
          model.coverImageId = mediaResponse.referenceId

          await this.topicsApi.cover(model.id, model.coverImageId)
        }

        await this.clearComponent()

        this.addMessage({
          message: `Topic '${model.name}' has been created.`
        })

        this.$router.push({
          name: 'topic-detail',
          params: { topicId: model.id }
        })
      } catch (e) {
        this.addError(e)
      }
    }
  }
}
</script>

<style></style>
