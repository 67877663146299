<template>
  <div class="text-center">
    <v-dialog v-model="show" class="modal" persistent width="500">
      <v-card>
        <v-card-title class="headline"> Remove Member </v-card-title>

        <v-card-text>
          Are you sure you want to remove <strong>{{ name }} </strong> from
          {{ organization.name }}?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" plain @click="cancel"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" tile large @click="onRemove">
            Yes, Remove Member
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    name: { type: String },
    value: Boolean
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('user', ['organization']),
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    onRemove() {
      this.$emit('onRemove')
    }
  }
}
</script>
