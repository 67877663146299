<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <group-form :item="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import { groups as groupsApi } from '@/services/api'
import groupForm from './form'

export default {
  components: {
    appToolbar,
    groupForm
  },
  data() {
    return {
      crumbs: [
        { text: 'Groups', to: { name: 'groups-index' } },
        { text: 'Create', disabled: true }
      ],
      model: {}
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      const response = await groupsApi.post(model)

      this.addMessage({
        message: `Group '${model.name}' has been created.`
      })

      this.$router.push({
        name: 'group-detail',
        params: { groupId: response.data.id }
      })
    }
  }
}
</script>

<style></style>
