import _ from 'lodash'
import AuthService from '@/services/auth.service.js'

import {
  profile as ProfileService,
  organizations as OrganizationApi
} from '@/services/api'

export default {
  namespaced: true,
  state: {
    profile: null,
    organization: null
  },
  mutations: {
    setUser(state, payload) {
      state.profile = payload.profile
    },
    setOrganization(state, payload) {
      state.organization = payload.organization
      AuthService.saveUserOrganization(payload.organization)
    }
  },
  actions: {
    async setUser({ commit }) {
      const user = await AuthService.getUser()

      if (user) {
        const profile = await ProfileService.fetch()
        commit('setUser', { profile })
        commit('setOrganization', {
          organization: AuthService.getUserOrganization(profile)
        })
      }
    },
    async setOrganization({ commit }, payload) {
      commit('setOrganization', { organization: payload.organization })
    },
    async clearUser({ commit }) {
      commit('setUser', { profile: null })
      commit('setOrganization', { organization: null })
    },
    async setAvailableOrganizations() {
      OrganizationApi.get()
    }
  },
  getters: {
    profile: (state) => {
      return state.profile || {}
    },
    organization: (state) => {
      return state.organization || {}
    },
    groups: (state) => {
      if (state.organization && state.organization.groups) {
        return Object.keys(state.organization.groups)
      }
      return []
    },

    isReady: (state, getters) => {
      //ToDo: the isInRole check should really run a check for content-creator permission not role
      return state.organization !== null &&
        getters.isAuthenticated &&
        getters.isInRoles(['content-creator', 'content-publisher'])
        ? true
        : false
    },
    isInRole: (state) => (role) => {
      if (role) {
        role = role.toLowerCase()
        if (state.organization && state.organization.roles) {
          return state.organization.roles.indexOf(role) > -1
        }
      }
      return false
    },
    isInRoles: (state, getters) => (roles, strict) => {
      strict = strict ?? false
      let result = false

      if (roles) {
        const roleArray = Array.isArray(roles) ? roles : roles.split(',')

        for (let i = 0; i < roleArray.length; i++) {
          if (getters.isInRole(roleArray[i])) {
            result = true
          }
        }

        if (!result && !strict) {
          result = getters.isSysAdmin || getters.isOrgAdmin
        }
      }
      return result
    },
    isAuthenticated: (state) => {
      return (
        !_.isEmpty(state.profile) &&
        state.profile &&
        state.profile !== null &&
        state.profile.user !== null &&
        !state.profile.expired &&
        state.organization !== null
      )
    },
    userCode: (state) => {
      return state.profile.userCode
    },
    userId: (state) => {
      return state.profile.userId
    },
    isSysAdmin: (state) => {
      if (state.profile) {
        return state.profile.isSysAdmin || false
      }
      return false
    },
    isOrgAdmin: (state, getters) => {
      return getters.isSysAdmin || getters.isInRole('orgadmin')
    }
  }
}
